import React  from "react";
import {Edit,SimpleForm, Create,TextInput } from 'react-admin';
import InputGuesser from 'UI/Common/ApiPlateform/InputGuesser';
import {useResourceOptions} from "../../hooks";
import { ArrayInput, SimpleFormIterator,ReferenceInput,SelectInput } from 'react-admin';

const SimpleFormCommon = ({...props}) => {
    const { writableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.edit) return null;
    return (
        <SimpleForm {...props}>
            {writableFields.name && <InputGuesser source='name' label='name'/>}
            {writableFields.setting && <InputGuesser source='setting' label='setting'/>}
            <ArrayInput source="attributes">
                <SimpleFormIterator>
                    <ReferenceInputFinal reference={'supplierattributes'} label={'attibute'} source={'attribute.id'}/>
                    <InputGuesser label={'value'} source={'value'}/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    )
};

export const ReferenceInputFinal = ({reference, ...props}) => {
    return (
        <ReferenceInput
            reference={reference}
            // validate={validate}
            {...props}
            allowEmpty>
            <SelectInput
                optionText={'code'}
            />
        </ReferenceInput>
    );
};

export const EditFinal = ({...props}) => {
    return (
        <Edit undoable={false} {...props }>
            <SimpleFormCommon {...props}/>
        </Edit>
    );
};

export const CreateFinal = ({...props}) => {
    return (
        <Create undoable={false} {...props}>
            <SimpleFormCommon/>
        </Create>
    );
};
