import React  from "react";
import {Edit, SimpleForm, Create, TextInput,FormTab,TabbedFormTabs} from 'react-admin';
import InputGuesser from 'UI/Common/ApiPlateform/InputGuesser';
import TabbedForm from 'UI/Common/TabbedForm';
import {useResourceOptions} from "../../hooks";
import {CurrencyInputField} from "../Common/Currency";

const SimpleFormCommon = ({...props}) => {
    const { writableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.edit) return null;

    return (
        <TabbedForm verticalTabsClassName={'vertical-tabbed-form'} tabs={<TabbedFormTabs orientation="vertical"/>} {...props}>
            <FormTab label="main">
                {writableFields.name && <InputGuesser source='name' label='Name'/>}
                {writableFields.description && <InputGuesser source='description' label='Description'/>}

                {writableFields.skuPrefix && <InputGuesser source='skuPrefix' label='SKU Prefix'/>}
                {writableFields.skuSuffix && <InputGuesser source='skuSuffix' label='SKU Suffix'/>}

                {writableFields.taxPercent && <InputGuesser source='taxPercent' label='Tax Percent'/>}

                {/*{writableFields.shippingCostPerLb && <InputGuesser source='shippingCostPerLb' label='Shipping Cost Per Lb'/>}*/}
                {writableFields.shippingCostFixed && <CurrencyInputField source='shippingCostFixed' label='Shipping Cost Fixed'/>}

                {writableFields.lowCostItemShippingCost && <CurrencyInputField source='lowCostItemShippingCost' label='Low Cost Item Shipping Cost'/>}
                {writableFields.lowCostItemPrice && <CurrencyInputField source='lowCostItemPrice' label='Low Cost Item Price'/>}

                {writableFields.conversionRate && <InputGuesser source='conversionRate' label='Conversion Rate'/>}

                {writableFields.handlingTime && <InputGuesser source='handlingTime' label='Handling Time'/>}
                {/*{writableFields.defaultWeight && <InputGuesser source='defaultWeight' label='Default Weight'/>}*/}

            </FormTab>
            <FormTab label="other">
                {writableFields.defaultQuantity && <InputGuesser source='defaultQuantity' label='Default Quantity'/>}

                {writableFields.channelFeePercent && <InputGuesser source='channelFeePercent' label='Channel FeePercent'/>}
                {writableFields.chhanelFeeFixed && <CurrencyInputField source='chhanelFeeFixed' label='Chhanel Fee Fixed'/>}

                {writableFields.profitPercent && <InputGuesser source='profitPercent' label='Profit Percent'/>}
                {writableFields.profitFixed && <CurrencyInputField source='profitFixed' label='Profit Fixed'/>}
                {writableFields.minimumPricePercent && <InputGuesser source='minimumPricePercent' label='Minimum Price Percent'/>}
                {writableFields.maximumPricePercent && <InputGuesser source='maximumPricePercent' label='Maximum PricePercent'/>}
                {writableFields.minimumPriceFixed && <CurrencyInputField source='minimumPriceFixed' label='Minimum Price Fixed'/>}
                {writableFields.maximumPriceFixed && <CurrencyInputField source='maximumPriceFixed' label='Maximum Price Fixed'/>}
                {writableFields.priceRoundUpto && <InputGuesser source='priceRoundUpto' label='priceRoundUpto'/>}
                {writableFields.defaultCondition && <InputGuesser source='defaultCondition' label='Default Condition'/>}
            </FormTab>
        </TabbedForm>
        // <SimpleForm {...props}>
        //     {writableFields.name && <InputGuesser source='name' label='Name'/>}
        //     {writableFields.description && <InputGuesser source='description' label='Description'/>}
        //
        //     {writableFields.skuPrefix && <InputGuesser source='skuPrefix' label='SKU Prefix'/>}
        //     {writableFields.skuSuffix && <InputGuesser source='skuSuffix' label='SKU Suffix'/>}
        //
        //     {writableFields.taxPercent && <InputGuesser source='taxPercent' label='Tax Percent'/>}
        //
        //     {/*{writableFields.shippingCostPerLb && <InputGuesser source='shippingCostPerLb' label='Shipping Cost Per Lb'/>}*/}
        //     {writableFields.shippingCostFixed && <CurrencyInputField source='shippingCostFixed' label='Shipping Cost Fixed'/>}
        //
        //     {writableFields.lowCostItemShippingCost && <CurrencyInputField source='lowCostItemShippingCost' label='Low Cost Item Shipping Cost'/>}
        //     {writableFields.lowCostItemPrice && <CurrencyInputField source='lowCostItemPrice' label='Low Cost Item Price'/>}
        //
        //     {writableFields.conversionRate && <InputGuesser source='conversionRate' label='Conversion Rate'/>}
        //
        //     {writableFields.handlingTime && <InputGuesser source='handlingTime' label='Handling Time'/>}
        //     {/*{writableFields.defaultWeight && <InputGuesser source='defaultWeight' label='Default Weight'/>}*/}
        //     {writableFields.defaultQuantity && <InputGuesser source='defaultQuantity' label='Default Quantity'/>}
        //
        //     {writableFields.channelFeePercent && <InputGuesser source='channelFeePercent' label='Channel FeePercent'/>}
        //     {writableFields.chhanelFeeFixed && <CurrencyInputField source='chhanelFeeFixed' label='Chhanel Fee Fixed'/>}
        //
        //     {writableFields.profitPercent && <InputGuesser source='profitPercent' label='Profit Percent'/>}
        //     {writableFields.profitFixed && <CurrencyInputField source='profitFixed' label='Profit Fixed'/>}
        //     {writableFields.minimumPricePercent && <InputGuesser source='minimumPricePercent' label='Minimum Price Percent'/>}
        //     {writableFields.maximumPricePercent && <InputGuesser source='maximumPricePercent' label='Maximum PricePercent'/>}
        //     {writableFields.minimumPriceFixed && <CurrencyInputField source='minimumPriceFixed' label='Minimum Price Fixed'/>}
        //     {writableFields.maximumPriceFixed && <CurrencyInputField source='maximumPriceFixed' label='Maximum Price Fixed'/>}
        //     {writableFields.priceRoundUpto && <InputGuesser source='priceRoundUpto' label='priceRoundUpto'/>}
        //     {writableFields.defaultCondition && <InputGuesser source='defaultCondition' label='Default Condition'/>}
        // </SimpleForm>
    )
};

export const EditFinal = ({...props}) => {
    return (
        <Edit undoable={false} {...props }>
            <SimpleFormCommon {...props}/>
        </Edit>
    );
};

export const CreateFinal = ({...props}) => {
    return (
        <Create undoable={false} {...props}>
            <SimpleFormCommon/>
        </Create>
    );
};
