import React, { Fragment }   from "react";
import { List,
    Datagrid,EditButton
} from 'react-admin';
import {ListActions} from "UI/Common/ListAction";
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import {FilterFinal} from "./Filter";
import {BulkActionButtons} from "./BulkAction";
import InlineEditButton from 'UI/Common/InlineEditButton';
import {EditFinal} from "./Edit";
import {useResourceOptions} from "../../hooks";

export const ListPlain = ({...props }) => {
    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List actions={<ListActions/>}  filters={false} bulkActionButtons={<BulkActionButtons operations={operations} /> }  {...props}>
                <Datagrid>
                    {readableFields.status && <FieldGuesser label={'status'} source={'status.name'}/>}
                    {readableFields.sourceProductStock && <FieldGuesser label={'product'} source={'sourceProductStock.product.name'}/>}
                    {readableFields.quantity && <FieldGuesser label={'quantity'} source={'quantity'}/>}
                    {readableFields.sourceProductStock && <FieldGuesser label={'Source'} source={'sourceProductStock.location.locationId'}/>}
                    {readableFields.sourceProductStock && <FieldGuesser label={'Warehouse'} source={'sourceProductStock.location.warehouse.name'}/>}

                    {readableFields.targetProductStock && <FieldGuesser label={'Target'} source={'targetProductStock.location.locationId'}/>}
                    {readableFields.targetProductStock && <FieldGuesser label={'Target Warehouse'} source={'targetProductStock.location.warehouse.name'}/>}

                    {readableFields.createdAt && <FieldGuesser label={'created at'} source={'createdAt'}/>}
                    {readableFields.updatedAt && <FieldGuesser label={'Last updated'} source={'updatedAt'}/>}

                    {/*<EnableToggleButton/>*/}

                    <InlineEditButton EditComponent={EditFinal}/>
                    {/*<EditButton/>*/}

                </Datagrid>
            </List>
        </Fragment>
    );
};

export const ListFinal = ListPlain;
