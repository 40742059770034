import React, { Fragment }   from "react";
import { List,
    Datagrid
} from 'react-admin';
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import {useResourceOptions} from "../../hooks";
import {DefaultListProps} from "../Common/DefaultListProps";

export const ListPlain = ({...props }) => {
    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List filters={false} {...DefaultListProps({operations})} {...props}>
                <Datagrid>
                    {readableFields.channelRequestId && <FieldGuesser  label={'channelRequestId'} source={'channelRequestId'}/>}
                    {readableFields.requestType && <FieldGuesser label={'requestType'} source={'requestType'}/>}
                    {readableFields.requestStatus && <FieldGuesser label={'requestStatus'} source={'requestStatus'}/>}
                    {readableFields.channelProcessingStartDateTime && <FieldGuesser showTime={true} label={'channelProcessingStartDateTime'} source={'channelProcessingStartDateTime'}/>}
                    {readableFields.channelProcessingCompleteDateTime && <FieldGuesser showTime={true} label={'channelProcessingCompleteDateTime'} source={'channelProcessingCompleteDateTime'}/>}
                    {readableFields.recordProcessed && <FieldGuesser label={'recordProcessed'} source={'recordProcessed'}/>}
                    {readableFields.recordSuccesful && <FieldGuesser label={'recordSuccesful'} source={'recordSuccesful'}/>}
                    {readableFields.recordFailed && <FieldGuesser label={'recordFailed'} source={'recordFailed'}/>}
                    {readableFields.recordWithWarning && <FieldGuesser label={'recordWithWarning'} source={'recordWithWarning'}/>}
                    {readableFields.channel && <FieldGuesser label={'channel'} source={'channel'}/>}
                    {readableFields.createdAt && <FieldGuesser showTime={true} label={'createdAt'} source={'createdAt'}/>}
                </Datagrid>
            </List>
        </Fragment>
    );
};

export const ListFinal = ListPlain;
