import React  from 'react';
import {Edit,SimpleForm, Create, CheckboxGroupInput,required} from 'react-admin';
import InputGuesser from 'UI/Common/ApiPlateform/InputGuesser';
import {useResourceOptions} from "../../hooks";
import {CurrencyInputField} from "../Common/Currency";
import {DefaultEditProps} from "../Common/DefaultEditProps";
import {weekDays} from "../../scheduler/weekDays";
import {TimeInput} from "../Common/DatePicker";
import { adsschedule} from "../../resources";

const SimpleFormCommon = ({save, ...props}) => {
    const { writableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.edit) return null;

    var d = new Date();
    if(props.record && props.record.hour && props.record.min){
        d.setUTCHours(props.record.hour[0]);
        d.setUTCMinutes(props.record.min[0]);
    }

    const newSave = (data, redirectTo, options) => {
        const time = new Date(data.time);
        data.hour = [time.getUTCHours()];
        data.min = [time.getUTCMinutes()];
        save(data, redirectTo, options)
    };

    return (
        <SimpleForm save={newSave} variant="outlined" {...props}>
            {writableFields.name && <InputGuesser validate={required()}  source='name'/>}
            {writableFields.note && <InputGuesser multiline source='note'/>}
            {/*{writableFields.hour && <InputGuesser source='hour[0]' label='hour'/>}*/}
            {/*{writableFields.min && <InputGuesser source='min[0]' label='min'/>}*/}
            {writableFields.budget && <CurrencyInputField validate={required()}  source='budget'/>}
            <TimeInput defaultValue={d.toISOString()} validate={required()}  source="time" label="Start time" options={{ format: 'hh:mm a' }} />
            <CheckboxGroupInput validate={required()} row={false} source={'dayOfWeek'} choices={weekDays} optionText="name" optionValue="value" />
        </SimpleForm>
    )
};

export const EditFinal = (props) => {
    return (
        <Edit {...DefaultEditProps(props)} {...props }>
            <SimpleFormCommon redirect={props.redirect}/>
        </Edit>
    );
};

export const CreateFinal = (props) => {
    return (
        <Create undoable={false} {...props}>
            <SimpleFormCommon redirect={props.redirect}/>
        </Create>
    );
};

export const CreateSchedule = ({resource, record, recordType}) => {
    const redirect = `/${resource}/${encodeURIComponent(record.id)}/show`;
    // const redirect = false;
    return (
        <CreateFinal redirect={redirect} component={'div'} record={{[recordType]: record.id}} resource={adsschedule.name} basePath={`/${adsschedule.name}`} />
    )
};
