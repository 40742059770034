import React  from "react";

import {
    Filter,
    TextInput,
} from 'react-admin';


export const FilterFinal = (props) => (
    <Filter {...props}>
        <TextInput label="Location Id" source="locationId" alwaysOn/>
    </Filter>
);
