import React  from "react";

import {
    Filter,
    TextInput,ReferenceInput,SelectInput
} from 'react-admin';

export const FilterFinal = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Supplier" source="supplier" reference="suppliers">
            <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput label="Product ID" source="supplierProductId"/>
        <TextInput label="UPC" source="product__upc"/>
        <TextInput label="ASIN" source="product__asin"/>
    </Filter>
);
