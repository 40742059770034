import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import {channelStats} from "../resources";
import {useGetList, CRUD_GET_LIST} from 'react-admin';

const useStyles = makeStyles(theme => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    compactTable:    {
        width: 'auto'
    },
}));

export default function Stats() {
    const classes = useStyles();
    const { data, ids, loading, error } = useGetList(channelStats.name, { page: 1, perPage: 10 }, { field: 'id', order: 'asc' }, {}, {action: CRUD_GET_LIST});

    return (
        <React.Fragment>
            <Title>On-board Sellers Statistics</Title>
            <Table className={classes.compactTable}  size="small" >
                {/*<TableHead>*/}
                {/*    <TableRow>*/}
                {/*        <TableCell>Type</TableCell>*/}
                {/*        <TableCell>Count</TableCell>*/}
                {/*        /!*<TableCell>Ship To</TableCell>*!/*/}
                {/*        /!*<TableCell>Payment Method</TableCell>*!/*/}
                {/*        /!*<TableCell align="right">Sale Amount</TableCell>*!/*/}
                {/*    </TableRow>*/}
                {/*</TableHead>*/}
                <TableBody>
                    {ids && ids.map(id => {
                        const rowdata = data[id];
                        return (
                            <>
                                <TableRow key={'active'}>
                                    <TableCell>{'Active'}</TableCell>
                                    <TableCell>{rowdata.active}</TableCell>
                                    {/*<TableCell>{row.shipTo}</TableCell>*/}
                                    {/*<TableCell>{row.paymentMethod}</TableCell>*/}
                                    {/*<TableCell align="right">{row.amount}</TableCell>*/}
                                </TableRow>
                                <TableRow key={'inactive'}>
                                    <TableCell>Inactive</TableCell>
                                    <TableCell>{rowdata.inactive}</TableCell>
                                    {/*<TableCell>{row.shipTo}</TableCell>*/}
                                    {/*<TableCell>{row.paymentMethod}</TableCell>*/}
                                    {/*<TableCell align="right">{row.amount}</TableCell>*/}
                                </TableRow>
                                <TableRow key={'total'}>
                                    <TableCell>Total</TableCell>
                                    <TableCell>{rowdata.total}</TableCell>
                                    {/*<TableCell>{row.shipTo}</TableCell>*/}
                                    {/*<TableCell>{row.paymentMethod}</TableCell>*/}
                                    {/*<TableCell align="right">{row.amount}</TableCell>*/}
                                </TableRow>
                            </>
                        )
                    })}
                </TableBody>
            </Table>
        </React.Fragment>
    );
}
