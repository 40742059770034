import React  from "react";
import {
    Filter,
} from 'react-admin';

export const FilterFinal = ({readableFields, ...props}) => {

    return (
        <Filter {...props}>
            {/*<TextInput source="parentASIN" label={'Parent ASIN'} alwaysOn/>*/}
        </Filter>
    )
};
