import {profile as ResourceData} from "../../resources";
import {EditFinal} from "./Edit";

export default {
    name:   ResourceData.name,
    list:   false,
    edit:   EditFinal,
    create:   false,
    show:   false,
    options:   { label: ResourceData.label },
};
