import React, {useContext} from "react";
import {SchemaAnalyzerContext} from "@api-platform/admin";
import {useResourceOptions} from "../../hooks";

export const Title = ({ resource, record }) => {
    const schemaAnalyzer = useContext(SchemaAnalyzerContext);
    const {resourceSchema} = useResourceOptions(resource);
    const TitleFieldName = schemaAnalyzer.getFieldNameFromSchema(resourceSchema);
    return <span>{record ? `"${record[TitleFieldName]}"` : ''}</span>;
};

export const EmptyTitle = () => null;

export const TitleBasedOnField = ({field, record}) => {
    return <span>{record ? `"${record[field]}"` : ''}</span>;
}
