import React, {Fragment, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  ArrayInput,
  BooleanInput,
  DateInput,
  DateTimeInput,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import {useResourceOptions} from "../../../hooks";
import {SchemaAnalyzerContext} from "@api-platform/admin";

export const IntrospectedInputGuesser = ({
                                           fields,
                                           readableFields,
                                           writableFields,
                                           schema,
                                           schemaAnalyzer,
                                             optionText,
                                             reference,
                                           ...props
                                         }) => {
    const field = writableFields[props.source];

  if (!field) {
    console.error(
        `Field ${props.source} not present inside API description for the resource ${props.resource}`,
    );

    return <Fragment />;
  }

  const validate =
      !props.validate && field.required ? [required()] : props.validate;

  if (null !== field.reference || field.embedded) {
      const referenceName = field.reference ? field.reference.name: field.embedded.name;
    if (1 === field.maxCardinality) {
      return (
          <ReferenceInput
              key={field.name}
              label={field.name}
              reference={referenceName}
              source={field.name}
              validate={validate}
              {...props}
              allowEmpty>
            <SelectInput
                optionText={optionText ? optionText : schemaAnalyzer.getFieldNameFromSchema(field.reference)}
            />
          </ReferenceInput>
      );
    }

    return (
        <ReferenceArrayInput
            key={field.name}
            label={field.name}
            reference={field.reference.name}
            source={field.name}
            validate={validate}
            {...props}
            allowEmpty>
          <SelectArrayInput
              optionText={optionText ? optionText : schemaAnalyzer.getFieldNameFromSchema(field.reference)}
          />
        </ReferenceArrayInput>
    );
  }

  const fieldType = schemaAnalyzer.getFieldType(field);

  if (fieldType === 'id') {
    const prefix = `/${props.resource}/`;

    props.format = value => {
      return value && 0 === value.indexOf(prefix)
          ? value.substr(prefix.length)
          : value;
    };

    props.parse = value => {
      return -1 !== value.indexOf(prefix) ? prefix + value : value;
    };
  }

  switch (fieldType) {
    case 'array':
      return (
          <ArrayInput
              key={field.name}
              source={field.name}
              validate={validate}
              {...props}>
            <SimpleFormIterator>
              <TextInput />
            </SimpleFormIterator>
          </ArrayInput>
      );

    case 'integer':
      return (
          <NumberInput
              key={field.name}
              source={field.name}
              validate={validate}
              {...props}
          />
      );

    case 'float':
      return (
          <NumberInput
              key={field.name}
              source={field.name}
              step="0.1"
              validate={validate}
              {...props}
          />
      );

    case 'boolean':
      return (
          <BooleanInput
              key={field.name}
              source={field.name}
              validate={validate}
              {...props}
          />
      );

    case 'date':
      return (
          <DateInput
              key={field.name}
              source={field.name}
              validate={validate}
              {...props}
          />
      );

    case 'dateTime':
      return (
          <DateTimeInput
              key={field.name}
              source={field.name}
              validate={validate}
              {...props}
          />
      );

    default:
      return (
          <TextInput
              key={field.name}
              source={field.name}
              validate={validate}
              {...props}
          />
      );
  }
};

const InputGuesser = props => {
  const {resourceSchema, fields,readableFields,writableFields} = useResourceOptions(props.resource);
  // console.log(readableFields);
  // console.log(resourceSchema);
  const schemaAnalyzer = useContext(SchemaAnalyzerContext);
  const field = fields ? fields[props.source]: null;
  if (!field) {
    // console.error(
    //     `Field "${props.source}" not present inside the api description for the resource "${props.resource}"`,
    // );

    return <TextInput {...props} />;
  }
  return <IntrospectedInputGuesser fields={fields} readableFields={readableFields} writableFields={writableFields} schema={resourceSchema} schemaAnalyzer={schemaAnalyzer} {...props} />
};

InputGuesser.propTypes = {
  source: PropTypes.string.isRequired,
};

export default InputGuesser;
