import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {FilteredResourceList} from "../Common/SubResourceList";
import {StatList} from "./List";
import {amazonDailyDisbursements, financialEventGroup, inventoryLog} from "../../resources";
import {StatList as InventoryList} from "../inventoryLog/List";
import {StatList as DailyDisbursementList} from "../amazonDailyDisbursement/List";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index &&
            <FilteredResourceList {...other}/>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export const StatTabbedList = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const yesterday = new Date(new Date().getTime() - 60 * 60 * 24 * 1000);

    return (
        <div className={classes.root}>
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="Outstanding Disbursement" {...a11yProps(0)} />
                <Tab label="Daily Disbursement" {...a11yProps(1)} />
                <Tab label="Inventory" {...a11yProps(2)} />
            </Tabs>
            <TabPanel sort={{ field: 'originalTotalAmount', order: 'DESC' }} targetResource={financialEventGroup.name} location={props.location} id={props.id} match={props.match} Component={StatList}  value={value} index={0} filter={{ processingStatus: 'Open' }} {...props}/>
            <TabPanel sort={{ field: 'amount', order: 'DESC' }} targetResource={amazonDailyDisbursements.name} location={props.location} id={props.id} match={props.match} Component={DailyDisbursementList}  value={value} index={1} filter={{ disbursementDate: {after :yesterday } }} {...props}/>
            <TabPanel sort={{ field: 'sellableInventoryValue', order: 'DESC' }} targetResource={inventoryLog.name} location={props.location} id={props.id} match={props.match} Component={InventoryList}  value={value} index={2}  filter={{ snapshotDateTime: {after :yesterday }}} {...props}/>
        </div>
    );
}
