import React, { Fragment }   from "react";
import { List,
    Datagrid
} from 'react-admin';
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import {FilterFinal} from "./Filter";
import InlineEditButton from 'UI/Common/InlineEditButton';
import {EditFinal} from "./Edit";
import {useResourceOptions} from "../../hooks";
import {DefaultListProps} from "../Common/DefaultListProps";
import {AmazonLink} from "../Common/Amazon";
import {EnableToggleButton} from "../Common/EnableDisableButton";
import {SubResourceList} from "../Common/SubResourceList";
import {productStock} from "../../resources";
import {ListFinal as ProductStockList} from "../productstock/List"
export const ListPlain = ({...props }) => {
    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List filters={<FilterFinal/>} {...DefaultListProps({operations})}  {...props}>
                <Datagrid expand={
                    <SubResourceList
                        Component={ProductStockList}
                        targetResource={productStock.name} recordType={'product'}
                        location={props.location} id={props.id} match={props.match}
                    />
                }>
                    {readableFields.code && <FieldGuesser label={'SKU'} source={'code'}/>}
                    {readableFields.asin && <AmazonLink domain={'com'} label={'asin'} source={'asin'}/>}
                    {readableFields.name && <FieldGuesser label={'Name'} source={'name'}/>}
                    {readableFields.upc && <FieldGuesser label={'upc'} source={'upc'}/>}
                    {/*{readableFields.stock && <FieldGuesser label={'stock'} source={'stock'}/>}*/}
                    {/*{readableFields.price && <FieldGuesser label={'price'} source={'price'}/>}*/}
                    <EnableToggleButton/>
                    <InlineEditButton EditComponent={EditFinal}/>
                    {/*<EditButton/>*/}

                </Datagrid>
            </List>
        </Fragment>
    );
};

export const ListFinal = ListPlain;
