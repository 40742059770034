import React  from "react";
import {
    Edit,
    Create,
    FormTab,
    ReferenceArrayInput,
    CheckboxGroupInput,
    TabbedForm,
    useQueryWithStore
} from 'react-admin';
import InputGuesser from 'UI/Common/ApiPlateform/InputGuesser';
import {useResourceOptions} from "../../hooks";
import {cloneDeepWith, values} from 'lodash';
import {TitleBasedOnField} from "../Common/Title";

function customizer(value) {
    if(value && value['@type'] === 'studytype') return value.id;
}

const SimpleFormCommon = ({record, ...props}) => {
    var newRecord = cloneDeepWith(record, customizer);
    const { writableFields, operations } = useResourceOptions(props.resource);
    const { data, ids, loading, error } = useQueryWithStore({
        type: 'getList',
        resource: 'roles',
        payload: {pagination: {}, sort: {} }
    });
    if(!operations || !operations.edit) return null;
    return (
        <TabbedForm record={newRecord}  {...props}>
            <FormTab label="User">
                {writableFields.username && <InputGuesser source='username' label='username'/>}
                {writableFields.email && <InputGuesser source='email' label='email'/>}
                {writableFields.enabled && <InputGuesser source='enabled' label='enabled'/>}
                {writableFields.plainPassword && <InputGuesser source='plainPassword' label='plainPassword'/>}
            </FormTab>
            <FormTab label="Permission">
                <ReferenceArrayInput source="groups" label={'groups'} reference="usergroups">
                    <CheckboxGroupInput row={false} optionText="name" />
                </ReferenceArrayInput>
                {data && !loading && !error && <CheckboxGroupInput label={'roles'} row={false} source={'realRoles'} choices={values(data)} optionText="name" optionValue="name" />}
            </FormTab>
        </TabbedForm>
    )
};


export const EditFinal = ({...props}) => {
    return (
        <Edit title={<TitleBasedOnField field={'username'}/>} undoable={false} {...props }>
            <SimpleFormCommon {...props}/>
        </Edit>
    );
};

export const CreateFinal = ({...props}) => {
    return (
        <Create undoable={false} {...props}>
            <SimpleFormCommon/>
        </Create>
    );
};
