import React  from "react";

import {Create,SimpleForm } from 'react-admin';
import { TextInput ,FileInput,FileField, ReferenceArrayInput,AutocompleteArrayInput, SelectArrayInput,SelectInput } from 'react-admin';
import { FormDataConsumer } from 'react-admin';
import { Input } from '@material-ui/core';

const domains = [
        { id: 'de', name: 'Germany' },
        { id: 'co.uk', name: 'UK' },
        { id: 'it', name: 'Italy' },
        { id: 'fr', name: 'France' },
        { id: 'es', name: 'Spain' },
];

const feedTypes = [
    // { id: 'ASIN_LIST_CLIENT', name: 'ASIN_LIST' },
    // { id: 'PRODUCT_ID_ASIN_UPC_LIST', name: 'PRODUCT_ID_ASIN_UPC_LIST' },
    { id: 'SHIPMENT_FILE', name: 'Shipment File' },

];

export const CreatePre = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput type={'hidden'} label={false} source="multipart" initialValue={true}  />
            <FileInput source="feedFile" parse={file => file} format={file=>file} multiple={false}>
                <FileField source="src" title="title" />
            </FileInput>
            <SelectInput source={'feedType'} choices={feedTypes}/>
            {/*<FormDataConsumer>*/}
            {/*    {*/}
            {/*        ({ formData, ...rest }) =>{*/}
            {/*            if(formData.feedType === 'REPRICER_PRODUCT_DATA_CLIENT')*/}
            {/*            return (*/}
            {/*                <ReferenceArrayInput label="Store" source="stores" reference="stores" >*/}
            {/*                    <AutocompleteArrayInput  optionText="name" />*/}
            {/*                </ReferenceArrayInput>*/}
            {/*            );*/}
            {/*            else return (*/}
            {/*                <SelectArrayInput source={'domains'} choices={domains} {...rest}/>*/}
            {/*            );*/}
            {/*        }*/}
            {/*    }*/}
            {/*</FormDataConsumer>*/}
        </SimpleForm>
    </Create>
);

export const CreateFinal = CreatePre;
