import React  from "react";

import {
    Filter,
    TextInput,ReferenceInput,SelectInput
} from 'react-admin';


export const FilterFinal = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Channel" source="channel" reference="channels">
            <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput label="SKU" source="sku"/>
        <TextInput label="ASIN" source="channelProductId"/>
    </Filter>
);
