import React from "react";
import BaseExportButton from './ExportButtonBase';
import {
    useListContext,
} from 'ra-core';

export  const ExportButton = ({...props }) => {
    const {filter,filterValues, total} = useListContext();
    const exportFilter = Object.assign({exportfile: 'csv', ...filter}, filterValues);
    return (
        <BaseExportButton
            disabled={total === 0}
            filterValues={exportFilter}
            {...props}
        />
    )
};

export  const ManualExportButton = ({filter,filterValues, ...props }) => {
    const exportFilter = Object.assign({exportfile: 'csv', ...filter}, filterValues);
    return (
        <BaseExportButton
            filterValues={exportFilter}
            {...props}
        />
    )
};
