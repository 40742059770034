import React, { Fragment }   from "react";
import { List,
    Datagrid
} from 'react-admin';
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import {FilterFinal} from "./Filter";
import InlineEditButton from 'UI/Common/InlineEditButton';
import {EditFinal} from "./Edit";
import {useResourceOptions} from "../../hooks";
import {CurrencyField} from "../Common/Currency";
import {DefaultListProps} from "../Common/DefaultListProps";
import {AmazonLink} from "../Common/Amazon";
import {EnableToggleButton} from "../Common/EnableDisableButton";

export const ListPlain = ({...props }) => {
    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List filters={<FilterFinal/>} {...DefaultListProps({operations})} {...props}>
                <Datagrid>
                    {readableFields.channel && <FieldGuesser link={false} label={'channel'} source={'channel'}/>}
                    {readableFields.sku && <FieldGuesser label={'sku'} source={'sku'}/>}
                    {readableFields.channelProductId && <AmazonLink domain={'com'} label={'channelProductId'} source={'channelProductId'}/>}
                    {readableFields.stock && <FieldGuesser label={'stock'} source={'stock'}/>}
                    {readableFields.price && <CurrencyField label={'price'} source={'price'}/>}
                    {readableFields.deleted && <FieldGuesser label={'deleted'} source={'deleted'}/>}
                    <EnableToggleButton/>
                    <InlineEditButton EditComponent={EditFinal}/>
                    {/*<EditButton/>*/}

                </Datagrid>
            </List>
        </Fragment>
    );
};

export const ListFinal = ListPlain;
