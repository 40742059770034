export const amazonShipmentPlan = {
    name: 'amazoninboundshipments',
    label: 'Shipment Plans',
};
export const amazonShipmentPlanItem = {
    name:   'amazoninboundshipmentplanitems',
    label:   'Shipment Items'
};
export const amazonShipmentPlanTransports = {
    name: 'amazoninboundshipmenttransports',
    label: 'Transports',
};
export const amazonOrder = {
    name: 'amazonorders',
    label: 'Orders',
};

export const invoice = {
    name: 'invoices',
    label: 'Invoices',
};

export const amazonOrderItem = {
    name: 'amazonorderitems',
    label: 'Amazon Order Item',
};

export const supplierProduct = {
    name: 'supplierproducts',
    label: 'Supplier Products',
};


export const channelProduct = {
    name: 'channelproducts',
    label: 'Channel Products',
};

export const channelSetting = {
    name: 'channelsettings',
    label: 'Channel Setting',
};


export const supplierSetting = {
    name: 'suppliersettings',
    label: 'Supplier Setting',
};

export const channel = {
    name: 'channels',
    label: 'Stores',
};


export const supplier = {
    name: 'suppliers',
    label: 'Supplier',
};

export const supplierAttributes = {
    name: 'supplierattributes',
    label: 'Supplier Attributes',
};

export const product = {
    name: 'products',
    label: 'Product',
};


export const cronLog = {
    name: 'cronlogs',
    label: 'Cron log',
};


export const order = {
    name: 'orders',
    label: 'Order',
};

export const channelRequestLog = {
    name: 'channelrequestlogs',
    label: 'Channel Request log',
};


export const locationType = {
    name: 'locationtypes',
    label: 'Location Type',
};


export const location = {
    name: 'locations',
    label: 'Location',
};


export const productStock = {
    name: 'productstocks',
    label: 'Product Stock',
};


export const warehouse = {
    name: 'warehouses',
    label: 'Warehouse',
};


export const productStockTransfer = {
    name: 'productstocktransfers',
    label: 'Stock Transfer',
};


export const transferStatus = {
    name: 'transferstatuses',
    label: 'Transfer Status',
};


export const productStockLog = {
    name: 'productstocklogs',
    label: 'Product Stock Log',
};


export const study = {
    name: 'studies',
    label: 'Study',
};


export const author = {
    name: 'authors',
    label: 'Author',
};


export const studytype = {
    name: 'studytypes',
    label: 'Study Type',
};


export const user = {
    name: 'users',
    label: 'User',
};

export const usergroup = {
    name: 'usergroups',
    label: 'User Groups',
};



export const roles = {
    name: 'roles',
    label: 'Roles',
};

export const profile = {
    name: 'profile',
    label: 'Profile',
};


export const adsaccount = {
    name: 'accounts',
    label: 'Account',
};


export const adsprofile = {
    name: 'profiles',
    label: 'Profile',
};



export const adsschedule = {
    name: 'schedulers',
    label: 'Schedules',
};


export const adsschedulelog = {
    name: 'schedulerlogs',
    label: 'Schedules Log',
};



export const adsportfolio = {
    name: 'portfolios',
    label: 'Portfolio',
};


export const adscampaign = {
    name: 'campaigns',
    label: 'Campaign',
};


export const financialEventGroup = {
    name: 'amazon_financial_event_groups',
    label: 'Financial Stats',
};

export const amazonDailyDisbursements = {
    name: 'amazon_daily_disbursements',
    label: 'Daily Disbursement',
};

export const amazonperformance = {
    name: 'amazonperformances',
    label: 'Performance',
};


export const inventoryLog = {
    name: 'inventory_logs',
    label: 'InventoryLog',
};

export const adconversionreport = {
    name: 'adconversionreports',
    label: 'Product Conversion Report',
};

export const channelStats = {
    name: 'channelstats',
    label: 'Channel Stats',
};

export const adproducts = {
    name: 'adproducts',
    label: 'Ad Products',
};

export const businessReportByChild = {
    name: 'business_report_by_children',
    label: 'Report By Child',
};


export const businessReportByParent = {
    name: 'business_report_by_parents',
    label: 'Report By Parent',
};


export const channelpermissions = {
    name: 'channelpermissions',
    label: 'Channel Permissions',
};


export const shipmentBatches = {
    name: 'shipment_batches',
    label: 'Batches',
};


export const walmartproduct = {
    name: 'walmartproducts',
    label: 'Walmart Products',
};


export const walmartsearch = {
    name: 'walmartsearches',
    label: 'Walmart Search',
};
