import React from "react";
import {
    Show,
    TabbedShowLayout,
    Tab, useMutation, FormWithRedirect, TextInput, SaveButton,useNotify, useRefresh
} from 'react-admin';
import {useResourceOptions} from "../../hooks";
import {Title} from "../Common/Title";
import {
    amazonDailyDisbursements,
    amazonperformance,
    channel,
    channelpermissions,
    financialEventGroup,
    inventoryLog
} from "../../resources";
import {PerformanceTab} from "./PerformanceTab";
import {SubResourceList} from "../Common/SubResourceList";
import {ListPlain} from "../amazonFinancialEventGroup/List";
import {ListPlain as DailyDisbursementList} from "../amazonDailyDisbursement/List";
import {ListPlain as InventoryList} from "../inventoryLog/List";
import {ListPlain as ChannelPermissionList} from "../channelpermissions/List";
import {Box, Toolbar} from "@material-ui/core";

export const ShowFinal = (props) =>
{
    const { readableFields, operations } = useResourceOptions(props.resource);
    return (
        <Show actions={false} title={<Title resource={channel.name}/>} {...props}>
            <RecordAwareComponent Component={TabbedShowLayout}/>
        </Show>
    );
};

const RecordAwareComponent = ({Component, ...props}) => {
    const {record} = props;
    return (
            <Component {...props}>
                <Tab label="Disbursement">
                    <SubResourceList recordType={'channel'} Component={ListPlain} targetResource={financialEventGroup.name} location={props.location} id={props.id} match={props.match}/>
                </Tab>
                <Tab label="Daily Disbursement">
                    <SubResourceList recordType={'channel'} Component={DailyDisbursementList} targetResource={amazonDailyDisbursements.name} location={props.location} id={props.id} match={props.match}/>
                </Tab>
                <Tab label="Inventory">
                    <SubResourceList recordType={'channel'} Component={InventoryList} targetResource={inventoryLog.name} location={props.location} id={props.id} match={props.match}/>
                </Tab>
                <Tab label="Performance">
                    <PerformanceTab targetResource={amazonperformance.name} location={props.location} id={props.id} match={props.match}/>
                </Tab>
                {record.owner &&
                    <Tab label="Permissions">
                        <VisitorForm/>
                        <SubResourceList recordType={'channel'} Component={ChannelPermissionList} targetResource={channelpermissions.name} location={props.location} id={props.id} match={props.match}/>
                    </Tab>
                }
            </Component>
    );
}

const VisitorForm = ({record, ...props}) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const [approve, { loading }] = useMutation({},
    {
        onSuccess: ({ data }) => {
            notify('User Added');
            refresh();
        },
        onFailure: (error) => {
            notify(`error: ${error.data['hydra:description']}`, 'warning')
        },
    });

    const save = (values) => {
        approve({
            type: 'update',
            resource: 'channelsper',
            payload: { id: `${record.id}/permission`, data: values }
        })
    };

    return (
        <FormWithRedirect
            {...props}
            save={save}
            saving={loading}
            render={formProps => (
                // here starts the custom form layout
                <form>
                    <Box display="flex" justifyContent="flex-start" >
                        <Box>
                            <TextInput source="usernameOrEmail" fullWidth />
                        </Box>
                        <Box>
                            <Toolbar>
                                <SaveButton
                                    redirect={false}
                                    label={'Add User'}
                                    saving={formProps.saving}
                                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                                />
                            </Toolbar>
                        </Box>
                    </Box>
                </form>
            )}
        />
    );
}
