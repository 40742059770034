import React, { Fragment }   from "react";
import { List,
    Datagrid,
} from 'react-admin';
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import {FilterFinal} from "./Filter";
import InlineEditButton from 'UI/Common/InlineEditButton';
import {EditFinal} from "./Edit";
import {useResourceOptions} from "../../hooks";
import {DefaultListProps} from "../Common/DefaultListProps";
import {EnableToggleButton} from "../Common/EnableDisableButton";
import {CurrencyField} from "../Common/Currency";
import {NameAsButton} from "../Common/Amazon";
import {ScheduleList} from "../adsscheduler/List";

export const ListPlain = ({...props }) => {
    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List perPage={100} filters={<FilterFinal/>} {...DefaultListProps({operations})} {...props}>
                <Datagrid expand={<ScheduleList recordType={'portfolio'}/>}>
                    {readableFields.name && <NameAsButton source={'name'}/>}
                    {readableFields.schedulersCount && <FieldGuesser label={'count'}  source={'schedulersCount'}/>}
                    {readableFields.budgetAmount && <CurrencyField source={'budgetAmount'}/>}
                    {/*{readableFields.budgetCurrencyCode && <FieldGuesser label={'budgetCurrencyCode'} source={'budgetCurrencyCode'}/>}*/}
                    {readableFields.budgetPolicy && <FieldGuesser source={'budgetPolicy'}/>}
                    {readableFields.startDate && <FieldGuesser showTime={true} source={'startDate'}/>}
                    {readableFields.endDate && <FieldGuesser showTime={true} source={'endDate'}/>}
                    {readableFields.inBudget && <FieldGuesser source={'inBudget'}/>}
                    {readableFields.state && <FieldGuesser source={'state'}/>}
                    {readableFields.enabled && <FieldGuesser source={'enabled'}/>}
                    <EnableToggleButton/>
                    <InlineEditButton EditComponent={EditFinal}/>
                    {/*<ShowButton/>*/}
                </Datagrid>
            </List>
        </Fragment>
    );
};

export const ListFinal = ListPlain;
