import React from 'react';
import { AppBar, useGetOne } from 'react-admin';
import MyUserMenu from './MyUserMenu';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {adsprofile} from "../resources";
import {profileSelector} from "../Reducer/profile";
import { useSelector } from 'react-redux'

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});


const MyAppBar = props => {
    const classes = useStyles();
    const profileId = useSelector(profileSelector);

    return (
        <AppBar {...props} userMenu={<MyUserMenu />} title='gomcoapp' position={'static'}>
            <Typography
                variant="h6"
                color="inherit"
                id="react-admin-title"
                className={classes.title}
            />
            {
                profileId && <ProfileTopBar profileId={profileId}/>
            }

        </AppBar>
    )
};

const ProfileTopBar = ({profileId}) => {
    const { data, loading, error, loaded } = useGetOne(adsprofile.name, profileId);
    const classes = useStyles();

    return (
        <Typography
            variant="h6"
            color="inherit"
            className={classes.title}
        >
            {loading ? '...' : data ? data.name: ''}
        </Typography>
    )

};

export default MyAppBar;
