import React, { Fragment }   from "react";
import { List,
    Datagrid,EditButton
} from 'react-admin';
import {ListActions} from "UI/Common/ListAction";
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import {FilterFinal} from "./Filter";
import {BulkActionButtons} from "./BulkAction";
import InlineEditButton from 'UI/Common/InlineEditButton';
import {EditFinal} from "./Edit";
import {useResourceOptions} from "../../hooks";

export const ListPlain = ({...props }) => {
    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List actions={<ListActions/>}  filters={false} bulkActionButtons={<BulkActionButtons operations={operations} /> }  {...props}>
                <Datagrid>
                    {readableFields.code && <FieldGuesser label={'code'} source={'code'}/>}
                    {/*{readableFields.name && <FieldGuesser label={'name'} source={'name'}/>}*/}
                    <InlineEditButton EditComponent={EditFinal}/>
                    {/*<EditButton/>*/}

                </Datagrid>
            </List>
        </Fragment>
    );
};

export const ListFinal = ListPlain;
