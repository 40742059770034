import React, { Fragment }   from "react";
import {
    Datagrid,
} from 'react-admin';
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import {List} from "../Common/List";
import {useResourceOptions} from "../../hooks";
import {EmptyTitle} from "../Common/Title";
import {CurrencyField} from "../Common/Currency";
import {DateField} from "../Common/DateField";
import {StatTabbedList} from "./StatList";
import {DefaultListProps} from "../Common/DefaultListProps";

export const ListPlain = ({...props }) => {
    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List  sort={{ field: 'fundTransferDate', order: 'DESC' }}  title={<EmptyTitle/>} {...DefaultListProps({operations})}  filters={false} bulkActionButtons={false}  {...props}>
                <Datagrid>
                    {readableFields.fundTransferDate && <DateField showTime={false} label={'fundTransferDate'} source={'fundTransferDate'}/>}
                    {readableFields.originalTotalAmount && <CurrencyField label={'originalTotalAmount'} source={'originalTotalAmount'}/>}
                    {readableFields.reserveAmount && <CurrencyField label={'reserveAmount'} source={'reserveAmount'}/>}
                    {readableFields.processingStatus && <FieldGuesser label={'processingStatus'} source={'processingStatus'}/>}
                    {readableFields.fundTransferStatus && <FieldGuesser label={'fundTransferStatus'} source={'fundTransferStatus'}/>}
                    {readableFields.accountTail && <FieldGuesser label={'accountTail'} source={'accountTail'}/>}
                </Datagrid>
            </List>
        </Fragment>
    );
};


export const StatList = ({...props }) => {
    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List title={<EmptyTitle/>} actions={false}  filters={false} bulkActionButtons={false}  {...props}>
                <Datagrid>
                    {readableFields.processingStatus && <FieldGuesser label={'Store'} source={'channel.name'}/>}
                    {readableFields.originalTotalAmount && <CurrencyField label={'originalTotalAmount'} source={'originalTotalAmount'}/>}
                    {readableFields.processingStatus && <FieldGuesser label={'processingStatus'} source={'processingStatus'}/>}
                </Datagrid>
            </List>
        </Fragment>
    );
};

export const ListFinal = StatTabbedList;
