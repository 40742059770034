import React  from "react";
import {Edit,SimpleForm, Create } from 'react-admin';
import InputGuesser from 'UI/Common/ApiPlateform/InputGuesser';
import {useResourceOptions} from "../../hooks";
import {Title} from "../Common/Title";

const SimpleFormCommon = ({...props}) => {
    const { writableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.edit) return null;

    return (
        <SimpleForm {...props}>
            {writableFields.name && <InputGuesser source='name' label='name'/>}
            {/*{writableFields.setting && <InputGuesser source='setting' label='setting'/>}*/}
            {writableFields.attributesValues && <InputGuesser source='attributesValues.merchantId' label='Merchant Id'/>}
            {writableFields.attributesValues && <InputGuesser source='attributesValues.marketplaceId' label='Marketplace Id'/>}
            {writableFields.attributesValues && <InputGuesser source='attributesValues.mwsToken' label='MWS Token'/>}
            {writableFields.attributesValues && <InputGuesser source='attributesValues.domain' label='Domain'/>}
        </SimpleForm>
    )
};

export const EditFinal = ({...props}) => {
    return (
        <Edit title={<Title {...props}/>} undoable={false} {...props }>
            <SimpleFormCommon {...props}/>
        </Edit>
    );
};

export const CreateFinal = ({...props}) => {
    return (
        <Create undoable={false} {...props}>
            <SimpleFormCommon/>
        </Create>
    );
};
