import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {DateField} from "../Common/DateField";
import {CurrencyField} from "../Common/Currency";

export const Invoice = props => {
    const {record: order} = props;
    // const order = record;
    console.log(order);
    return (
        <>

            <Grid container spacing={2}>
                <Address prefix={'Shipping'} record={order.shippingAddress}/>
                {order.billingAddress && <Address prefix={'Billing'} record={order.billingAddress}/>}
                <Grid item xs={6} sm={6} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant={"body1"} >
                                <b>Warehouse Center Id:</b> {order.warehouseCenterId}
                            </Typography>
                            <Typography variant={"body1"} >
                                <b> VAT Number:</b> {order.vatNumber}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant={"body1"} >
                                <b>Order Number:</b> {order.channelOrderId}
                            </Typography>
                            <Typography variant={"body1"} >
                                <b> Order Date:</b> <DateField record={order} source={'createdAt'}/>
                            </Typography>
                            <Typography variant={"body1"} >
                                <b> Latest Ship Date:</b> <DateField record={order} source={'latestShipDate'}/>
                            </Typography>
                            <Typography variant={"body1"} >
                                <b> Latest Delivery Date:</b> <DateField record={order} source={'latestDeliveryDate'}/>
                            </Typography>
                            {/*<Typography variant={"body1"} >*/}
                            {/*    <b> Invoice Number:</b> {order.vatInvoiceNumber}*/}
                            {/*</Typography>*/}
                            {/*<Typography variant={"body1"} >*/}
                            {/*    <b> Invoice Date:</b> {order.invoiceDate}*/}
                            {/*</Typography>*/}

                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Card>
                        <CardContent>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Sl No</TableCell>
                                            <TableCell>Description</TableCell>
                                            <TableCell>Unit Price</TableCell>
                                            <TableCell>Discount</TableCell>
                                            <TableCell>Qty</TableCell>
                                            <TableCell>Net Amount</TableCell>
                                            <TableCell>Tax Rate</TableCell>
                                            <TableCell>Tax Type</TableCell>
                                            <TableCell>Tax Amount</TableCell>
                                            <TableCell>Total Amount</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            order.items.map((orderItem, index) => {
                                                return (
                                                    <ItemRow orderItem={orderItem} key={index}/>
                                                )
                                            })
                                        }
                                        <TotalAmount order={order}/>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

        </>
    );

};

const ItemRow = ({orderItem}) =>{
    var rowSpan = 1;
    // if(orderItem.shippingPriceAmount) rowSpan++;
    // if(orderItem.giftWrapPriceAmount) rowSpan++;
    return (
        <>
            <TableRow>
                <TableCell rowSpan={rowSpan}>
                    1
                </TableCell>
                <TableCell>
                    {orderItem.title}<br/>
                    <b>SKU:</b> {orderItem.sku}<br/>
                    <b>Channel Product Id:</b> {orderItem.channelProductId}<br/>
                </TableCell>
                <TableCell>{(orderItem.itemAmount -  orderItem.itemTaxAmount) / orderItem.quantityOrdered}</TableCell>
                <TableCell>-</TableCell>
                <TableCell>{orderItem.quantityOrdered}</TableCell>
                <TableCell>{orderItem.itemPriceAmount - orderItem.itemTaxAmount}</TableCell>
                <TableCell>???</TableCell>
                <TableCell>???</TableCell>
                <TableCell>{orderItem.itemTaxAmount}</TableCell>
                <TableCell><CurrencyField record={orderItem} source={'itemAmount'}/></TableCell>
            </TableRow>
            {orderItem.shippingPriceAmount &&
                <TableRow>
                    <TableCell>Shipping</TableCell>
                    <TableCell>{orderItem.shippingPriceAmount}</TableCell>
                    <TableCell>-{orderItem.shippingDiscountAmount}</TableCell>
                    <TableCell> </TableCell>
                    <TableCell>{orderItem.shippingPriceAmount - orderItem.shippingTaxAmount}</TableCell>
                    <TableCell>???</TableCell>
                    <TableCell>??</TableCell>
                    <TableCell>{orderItem.shippingTaxAmount}</TableCell>
                    <TableCell>{orderItem.shippingPriceAmount}</TableCell>
                </TableRow>
            }
            {orderItem.giftWrapPriceAmount &&
                <TableRow>
                    <TableCell>Gift Wrap</TableCell>
                    <TableCell>{orderItem.giftWrapPriceAmount}</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell> </TableCell>
                    <TableCell>{orderItem.giftWrapPriceAmount - orderItem.giftWrapTaxAmount}</TableCell>
                    <TableCell>???</TableCell>
                    <TableCell>??</TableCell>
                    <TableCell>{orderItem.giftWrapTaxAmount}</TableCell>
                    <TableCell>{orderItem.giftWrapPriceAmount}</TableCell>
                </TableRow>
            }
        </>
    );
};

const TotalAmount = ({order}) =>{
    return (
        <>
            <TableRow>
                <TableCell align={"right"} colSpan={8}>
                    <b>Total</b>
                </TableCell>
                <TableCell><b>{order.totalTax}</b></TableCell>
                <TableCell><b><CurrencyField record={order} source={'orderTotalAmount'}/></b></TableCell>


            </TableRow>


        </>
    );
};

const Address  = ({prefix = 'billing',record, ...props}) => {
    return (
        <Grid item xs={6} sm={6} md={6}>
            <Card>
                <CardContent>
                    <Typography variant={"body1"}><b>{prefix} Address</b></Typography>
                    <Typography variant={"body1"}>
                        {record.fullName}
                    </Typography>
                    <Typography variant={"body1"} gutterBottom>
                        {record.street}<br />
                        {record.addressLine2}<br />
                        {record.addressLine3}<br />
                        {record.city}, {record.provinceName}<br />
                        {record.countryCode} - {record.postcode}<br />
                        {record.phoneNumber}
                    </Typography>
                </CardContent>
            </Card>

        </Grid>
    )
};
