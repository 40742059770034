import React  from "react";

import {
    Filter,
} from 'react-admin';

export const FilterFinal = (props) => (
    <Filter {...props}>
    </Filter>
);
