import React, { Fragment }   from "react";
import {
    Datagrid,DeleteButton
} from 'react-admin';
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import {List} from "../Common/List";
import {useResourceOptions} from "../../hooks";
import {EmptyTitle} from "../Common/Title";
import {DefaultListProps} from "../Common/DefaultListProps";

export const ListPlain = ({...props }) => {
    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List sort={{ field: 'id', order: 'DESC' }} title={<EmptyTitle/>} {...DefaultListProps({operations})}  filters={false} bulkActionButtons={false}  {...props}>
                <Datagrid>
                    {readableFields.user && <FieldGuesser label={'User'} source={'user.username'}/>}
                    <DeleteButtonCustom/>
                </Datagrid>
            </List>
        </Fragment>
    );
};

export const ListFinal = ListPlain;

const DeleteButtonCustom = (props) => {
    const {record} = props;
    const message = `Do you want to remove user ${record.user.username}?`;
    return (<DeleteButton confirmTitle={message} confirmContent={message} redirect={false} undoable={false} {...props}/>);

}
