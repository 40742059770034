import React, { Fragment }   from "react";
import {
    Datagrid,
} from 'react-admin';
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import {List} from "../Common/List";
import {useResourceOptions} from "../../hooks";
import {EmptyTitle} from "../Common/Title";
import {DateField} from "../Common/DateField";
import {DefaultListProps} from "../Common/DefaultListProps";

export const ListPlain = ({...props }) => {
    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List sort={{ field: 'startDate', order: 'DESC' }} title={<EmptyTitle/>} {...DefaultListProps({operations})}  filters={false} bulkActionButtons={false}  {...props}>
                <Datagrid>
                    {readableFields.startDate && <DateField showTime={false} label={'startDate'} source={'startDate'}/>}
                    {readableFields.endDate && <DateField showTime={false} label={'endDate'} source={'endDate'}/>}
                    {readableFields.defectPercentage && <FieldGuesser label={'defectPercentage'} source={'defectPercentage'}/>}
                    {readableFields.defectCount && <FieldGuesser label={'defectCount'} source={'defectCount'}/>}
                    {readableFields.totalCount && <FieldGuesser label={'totalCount'} source={'totalCount'}/>}
                </Datagrid>
            </List>
        </Fragment>
    );
};


export const ListFinal = ListPlain;
