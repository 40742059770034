import React  from "react";

import { Show,
    TabbedShowLayout,
    Tab,
} from 'react-admin';
import {TitleBasedOnField} from "../Common/Title";
import {adconversionreport} from "../../resources";
import {AdConversionReportList} from "../adconversionreport/List";

export const ShowFinal = (props) =>
{
    // const { readableFields, operations } = useResourceOptions(props.resource);
    return (
        <Show title={<TitleBasedOnField field={'sku'}/>} {...props}>
            <TabbedShowLayout>
                <Tab label="Report">
                    <AdConversionReportList recordType={'product'} targetResource={adconversionreport.name} location={props.location} id={props.id} match={props.match}/>
                </Tab>
                {/*<Tab label="Add Schedule">*/}
                {/*    <CreateSchedule recordType={'portfolio'}/>*/}
                {/*</Tab>*/}
            </TabbedShowLayout>
        </Show>
    );
};
