import React, { Fragment }   from "react";
import { List,
    Datagrid, ShowButton
} from 'react-admin';
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import {FilterFinal} from "./Filter";
import InlineEditButton from 'UI/Common/InlineEditButton';
import {useResourceOptions} from "../../hooks";
import {CurrencyField} from "../Common/Currency";
import {DefaultListProps} from "../Common/DefaultListProps";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


export const ListPlain = ({...props }) => {
    const { operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List filterDefaultValues={{ state: 'Unshipped' }} filters={<FilterFinal/>} {...DefaultListProps({operations})} {...props}>
                <TabbedDatagrid/>
            </List>
        </Fragment>
    );
};


const TabbedDatagrid =  (props) => {
    // console.log(props);
    const {filterValues, setFilters} = props;
    const [value, setValue] = React.useState('Shipped');

    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setFilters({ ...filterValues, state: newValue });
    };

    const tabs = [
        {id: 'Unshipped', name: 'Unshipped'},
        {id: 'Pending', name: 'Pending'},
        {id: 'Shipped', name: 'Shipped'},
        {id: 'Canceled', name: 'Canceled'},
    ];

    return (
        <Fragment>
            <Tabs
                fullWidth
                centered
                value={value}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {tabs.map(choice => (
                    <Tab
                        key={choice.id}
                        label={choice.name}
                        value={choice.id}
                    />
                ))}
            </Tabs>
            <Datagrid {...props}>
                {readableFields.channel && <FieldGuesser link={false} label={'channel'} source={'channel'}/>}
                {readableFields.channelOrderId && <FieldGuesser  label={'Order Id'} source={'channelOrderId'}/>}
                {readableFields.createdAt && <FieldGuesser showTime={true} label={'createdAt'} source={'createdAt'}/>}
                {readableFields.latestShipDate && <FieldGuesser showTime={true} label={'latestShipDate'} source={'latestShipDate'}/>}
                {readableFields.state && <FieldGuesser label={'state'} source={'state'}/>}
                {readableFields.orderTotalAmount && <CurrencyField label={'Total'} source={'orderTotalAmount'}/>}
                <ShowButton/>
                {/*<InlineEditButton EditComponent={EditFinal}/>*/}
                {/*<EditButton/>*/}

            </Datagrid>

        </Fragment>
    )
};

export const ListFinal = ListPlain;
