import React  from "react";
import {Edit,SimpleForm, Create } from 'react-admin';
import InputGuesser from 'UI/Common/ApiPlateform/InputGuesser';
import {useResourceOptions} from "../../hooks";
import {Title} from "../Common/Title";
import {CurrencyInputField} from "../Common/Currency";

const SimpleFormCommon = ({...props}) => {
    const { writableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.edit) return null;

    return (
        <SimpleForm {...props}>
            {writableFields.name && <InputGuesser source='name' label='name'/>}
            {writableFields.description && <InputGuesser source='description' label='Description'/>}
            {writableFields.skuPrefix && <InputGuesser source='skuPrefix' label='SKU Prefix'/>}
            {writableFields.skuSuffix && <InputGuesser source='skuSuffix' label='SKU Suffix'/>}
            {writableFields.taxPercent && <InputGuesser source='taxPercent' label='Tax Percent'/>}
            {writableFields.customDutyPercent && <InputGuesser source='customDutyPercent' label='Custom Duty Percent'/>}
            {writableFields.shippingCostPerLb && <CurrencyInputField source='shippingCostPerLb' label='Shipping Cost Per Lb'/>}
            {writableFields.shippingCostFixed && <CurrencyInputField source='shippingCostFixed' label='Shipping Cost Fixed'/>}
            {writableFields.lowCostItemShippingCost && <CurrencyInputField source='lowCostItemShippingCost' label='low Cost Item Shipping Cost'/>}
            {writableFields.lowCostItemPrice && <CurrencyInputField source='lowCostItemPrice' label='lowCostItemPrice'/>}
            {writableFields.conversionRate && <InputGuesser source='conversionRate' label='Conversion Rate'/>}
            {writableFields.handlingTime && <InputGuesser source='handlingTime' label='Handling Time'/>}
            {writableFields.defaultWeight && <InputGuesser source='defaultWeight' label='Defaul tWeight'/>}
            {writableFields.minQuantity && <InputGuesser source='minQuantity' label='Min Quantity'/>}
        </SimpleForm>
    )
};

export const EditFinal = ({...props}) => {
    return (
        <Edit title={<Title {...props}/>} undoable={false} {...props }>
            <SimpleFormCommon {...props}/>
        </Edit>
    );
};

export const CreateFinal = ({...props}) => {
    return (
        <Create undoable={false} {...props}>
            <SimpleFormCommon/>
        </Create>
    );
};
