import React, { Fragment }   from "react";
import {
    Datagrid,
} from 'react-admin';
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import {List} from "../Common/List";
import {useResourceOptions} from "../../hooks";
import {EmptyTitle} from "../Common/Title";
import {CurrencyField} from "../Common/Currency";
import {DateField} from "../Common/DateField";
import {DefaultListProps} from "../Common/DefaultListProps";

export const ListPlain = ({...props }) => {
    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List sort={{ field: 'snapshotDateTime', order: 'DESC' }} title={<EmptyTitle/>} {...DefaultListProps({operations})} filters={false} bulkActionButtons={false}  {...props}>
                <Datagrid>
                    {readableFields.snapshotDateTime && <DateField showTime={false} label={'snapshotDateTime'} source={'snapshotDateTime'}/>}
                    {readableFields.sellableInventoryValue && <CurrencyField label={'sellableInventoryValue'} source={'sellableInventoryValue'}/>}
                    {readableFields.sellableTotalQuantity && <FieldGuesser label={'sellableTotalQuantity'} source={'sellableTotalQuantity'}/>}
                    {readableFields.inTransitInventoryValue && <CurrencyField label={'inTransitInventoryValue'} source={'inTransitInventoryValue'}/>}
                    {readableFields.inTransitTotalQuantity && <FieldGuesser label={'inTransitTotalQuantity'} source={'inTransitTotalQuantity'}/>}
                </Datagrid>
            </List>
        </Fragment>
    );
};

export const StatList = ({...props }) => {
    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List title={<EmptyTitle/>} actions={false}  filters={false} bulkActionButtons={false}  {...props}>
                <Datagrid>
                    {readableFields.channel && <FieldGuesser label={'Store'} source={'channel.name'}/>}
                    {readableFields.snapshotDateTime && <DateField showTime={false} label={'snapshotDateTime'} source={'snapshotDateTime'}/>}
                    {readableFields.sellableInventoryValue && <CurrencyField label={'sellableInventoryValue'} source={'sellableInventoryValue'}/>}
                    {readableFields.sellableTotalQuantity && <FieldGuesser label={'sellableTotalQuantity'} source={'sellableTotalQuantity'}/>}
                    {readableFields.inTransitInventoryValue && <CurrencyField label={'inTransitInventoryValue'} source={'inTransitInventoryValue'}/>}
                    {readableFields.inTransitTotalQuantity && <FieldGuesser label={'inTransitTotalQuantity'} source={'inTransitTotalQuantity'}/>}
                </Datagrid>
            </List>
        </Fragment>
    );
};

export const ListFinal = ListPlain;
