import React, { Fragment }   from "react";
import { List,
    Datagrid
} from 'react-admin';
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import InlineEditButton from 'UI/Common/InlineEditButton';
import {EditFinal} from "./Edit";
import {useResourceOptions} from "../../hooks";
import {DefaultListProps} from "../Common/DefaultListProps";

export const ListPlain = ({...props }) => {
    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List filters={false} {...DefaultListProps({operations})} {...props}>
                <Datagrid>
                    {readableFields.name && <FieldGuesser  label={'Name'} source={'name'}/>}
                    {readableFields.description && <FieldGuesser label={'description'} source={'description'}/>}
                    {readableFields.successful && <FieldGuesser label={'successful'} source={'successful'}/>}
                    {readableFields.lastRun && <FieldGuesser showTime={true} label={'Last Run At'} source={'lastRun'}/>}
                    {readableFields.recordProcessed && <FieldGuesser label={'Record Processed'} source={'recordProcessed'}/>}
                    <InlineEditButton EditComponent={EditFinal}/>
                </Datagrid>
            </List>
        </Fragment>
    );
};

export const ListFinal = ListPlain;
