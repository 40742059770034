import React, { Fragment }   from "react";
import { List,
    Datagrid
} from 'react-admin';
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import {FilterFinal} from "./Filter";
import {useResourceOptions} from "../../hooks";
import {DefaultListProps} from "../Common/DefaultListProps";
import {EnableToggleButton} from "../Common/EnableDisableButton";
import InlineEditButton from "../Common/InlineEditButton";
import {EditFinal} from './Edit';
import {CurrencyField} from "../Common/Currency";
import {NameAsButton} from "../Common/Amazon";
import {ScheduleList} from "../adsscheduler/List";


export const ListPlain = ({...props }) => {
    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List perPage={100} filters={<FilterFinal/>} {...DefaultListProps({operations})} {...props}>
                <Datagrid expand={<ScheduleList recordType={'campaign'}/>}>
                    {/*{readableFields.campaignId && <FieldGuesser source={'campaignId'}/>}*/}
                    {readableFields.name && <NameAsButton source={'name'}/>}
                    {readableFields.schedulersCount && <FieldGuesser label={'count'}  source={'schedulersCount'}/>}
                    {/*{readableFields.campaignType && <FieldGuesser label={'campaignType'} source={'campaignType'}/>}*/}
                    {readableFields.targetingType && <FieldGuesser source={'targetingType'}/>}
                    {/*{readableFields.premiumBidAdjustment && <FieldGuesser source={'premiumBidAdjustment'}/>}*/}
                    {readableFields.dailyBudget && <CurrencyField source={'dailyBudget'}/>}
                    {readableFields.startDate && <FieldGuesser showTime={true} source={'startDate'}/>}
                    {readableFields.endDate && <FieldGuesser showTime={true} source={'endDate'}/>}
                    {readableFields.state && <FieldGuesser source={'state'}/>}
                    {readableFields.enabled && <FieldGuesser source={'enabled'}/>}
                    <EnableToggleButton/>
                    {/*<InlineEditButton label={'Schedules'} EditComponent={ShowFinal}/>*/}
                    <InlineEditButton EditComponent={EditFinal}/>
                    {/*<DialogueCreateButton targetType={'campaign'} EditComponent={SchCreate}/>*/}
                    {/*<EditButton/>*/}
                    {/*<ShowButton/>*/}
                </Datagrid>
            </List>
        </Fragment>
    );
};

export const ListFinal = ListPlain;
