import React  from "react";

import {
    Filter,
    TextInput,
} from 'react-admin';


export const FilterFinal = (props) => (
    <Filter {...props}>
        <TextInput label="barcode" source="upc" alwaysOn/>
        <TextInput label="ASIN" source="asin"/>
    </Filter>
);
