import React  from "react";
import {Edit, Create,FormTab,TabbedFormTabs, ArrayInput, SimpleFormIterator, TextInput} from 'react-admin';
import InputGuesser from 'UI/Common/ApiPlateform/InputGuesser';
import TabbedForm from 'UI/Common/TabbedForm';
import {useResourceOptions} from "../../hooks";
import RichTextInput from 'ra-input-rich-text';

const SimpleFormCommon = ({...props}) => {
    const { writableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.edit) return null;
    var toolbarOptions = [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
    ];

    return (
        <TabbedForm verticalTabsClassName={'vertical-tabbed-form'} tabs={<TabbedFormTabs orientation="vertical"/>} {...props}>
            <FormTab label="Title">
                {writableFields.title && <InputGuesser source='title' label='Title'/>}

            </FormTab>
            <FormTab label="Author">
                <ArrayInput source="authors">
                    <SimpleFormIterator>
                        <TextInput label={'Name'} source="name" />
                        <TextInput label={'Qualification'} source="qualification" />
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label="Abstract">
                {writableFields.abstract && <RichTextInput source='abstract' label='Abstract'/>}
            </FormTab>
            <FormTab label="Introduction">
                {writableFields.introduction && <RichTextInput toolbar={toolbarOptions} options={{ multiLine: true }}  source='introduction' label='Introduction'/>}
            </FormTab>
            <FormTab label="Discussion">
                {writableFields.discussion && <RichTextInput source='discussion' label='Discussion'/>}
            </FormTab>
            <FormTab label="Conclusion">
                {writableFields.conclusion && <RichTextInput source='conclusion' label='Conclusion'/>}
            </FormTab>
            <FormTab label="Consent">
                {writableFields.consent && <RichTextInput source='consent' label='Consent'/>}
            </FormTab>
            <FormTab label="Acknwoledgemment">
                {writableFields.acknwoledgemment && <RichTextInput source='acknwoledgemment' label='Acknwoledgemment'/>}
            </FormTab>
            <FormTab label="Source Of Funding">
                {writableFields.introduction && <RichTextInput source='sourceOfFunding' label='Source Of Funding'/>}
            </FormTab>
        </TabbedForm>

    )
};

export const EditFinal = ({...props}) => {
    return (
        <Edit undoable={false} {...props }>
            <SimpleFormCommon {...props}/>
        </Edit>
    );
};

export const CreateFinal = ({...props}) => {
    return (
        <Create undoable={false} {...props}>
            <SimpleFormCommon/>
        </Create>
    );
};
