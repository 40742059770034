import React  from "react";
import {
    Edit,
    Create,
    FormTab,
    CheckboxGroupInput,
    TabbedForm,
    useQueryWithStore
} from 'react-admin';
import InputGuesser from 'UI/Common/ApiPlateform/InputGuesser';
import {useResourceOptions} from "../../hooks";
import {cloneDeepWith, values} from 'lodash';
import {TitleBasedOnField} from "../Common/Title";

function customizer(value) {
    if(value && value['@type'] === 'studytype') return value.id;
}

const SimpleFormCommon = ({record, ...props}) => {
    var newRecord = cloneDeepWith(record, customizer);
    const { writableFields, operations } = useResourceOptions(props.resource);
    const { data, ids, loading, error } = useQueryWithStore({
        type: 'getList',
        resource: 'roles',
        payload: {pagination: {}, sort: {} }
    });
    if(!operations || !operations.edit) return null;
    return (
        <TabbedForm record={newRecord}  {...props}>
            <FormTab label="User">
                {writableFields.name && <InputGuesser source='name' label='name'/>}
                {data && !loading && !error && <CheckboxGroupInput row={false} source={'roles'} choices={values(data)} optionText="name" optionValue="name" />}
            </FormTab>
        </TabbedForm>
    )
};


export const EditFinal = ({...props}) => {
    return (
        <Edit title={<TitleBasedOnField field={'name'}/>} undoable={false} {...props }>
            <SimpleFormCommon {...props}/>
        </Edit>
    );
};

export const CreateFinal = ({...props}) => {
    return (
        <Create undoable={false} {...props}>
            <SimpleFormCommon/>
        </Create>
    );
};
