import * as React from 'react';
import { useCallback, FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@material-ui/icons/GetApp';
import {
    fetchRelatedRecords,
    useDataProvider,
    useNotify,
    useListContext,
    Sort,
    Exporter,
    Filter,
} from 'ra-core';

import {Button} from 'ra-ui-materialui';

const ExportButton: FunctionComponent<ExportButtonProps> = props => {
    const {
        maxResults = 1000,
        onClick,
        label = 'ra.action.export',
        icon = defaultIcon,
        exporter: customExporter,
        sort, // deprecated, to be removed in v4
        filterValues,
        resource,
        ...rest
    } = props;
    console.log(props);

    const {
        filterValues: filterValuesFromContext,
        resource: resourceFromContext,
        currentSort: sortFromContext,
        exporter: exporterFromContext,
    } = useListContext(props);
    const exporter = customExporter || exporterFromContext;
    const finalSort = sort || sortFromContext;
    const finalResource = resource || resourceFromContext;
    const finalFilterValues = filterValues || filterValuesFromContext;

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const handleClick = useCallback(
        event => {
            dataProvider
                .getList(finalResource, {
                    sort: finalSort,
                    filter: finalFilterValues,
                    pagination: { page: 1, perPage: maxResults },
                })
                .then(
                    ({ data }) =>
                        exporter &&
                        exporter(
                            data,
                            fetchRelatedRecords(dataProvider),
                            dataProvider,
                            finalResource
                        )
                )
                .catch(error => {
                    console.error(error);
                    notify('ra.notification.http_error', 'warning');
                });
            if (typeof onClick === 'function') {
                onClick(event);
            }
        },
        [
            finalSort,
            dataProvider,
            exporter,
            finalFilterValues,
            maxResults,
            notify,
            onClick,
            finalResource,
        ]
    );

    return (
        <Button
            onClick={handleClick}
            label={label}
            {...sanitizeRestProps(rest)}
        >
            {icon}
        </Button>
    );
};

const defaultIcon = <DownloadIcon />;

const sanitizeRestProps = ({
                               basePath,
                               filterValues,
                               resource,
                               ...rest
                           }: Omit<ExportButtonProps, 'sort' | 'maxResults' | 'label' | 'exporter'>) =>
    rest;

interface Props {
    basePath?: string;
    exporter?: Exporter;
    filterValues?: Filter;
    icon?: JSX.Element;
    label?: string;
    maxResults?: number;
    onClick?: (e: Event) => void;
    resource?: string;
    sort?: Sort;
}

export type ExportButtonProps = Props;

ExportButton.propTypes = {
    basePath: PropTypes.string,
    exporter: PropTypes.func,
    filterValues: PropTypes.object,
    label: PropTypes.string,
    maxResults: PropTypes.number,
    resource: PropTypes.string.isRequired,
    sort: PropTypes.exact({
        field: PropTypes.string,
        order: PropTypes.string,
    }),
    icon: PropTypes.element,
};

export default ExportButton;
