import React from "react";
import {  Button } from 'react-admin';
import { useMutation, useNotify, useRedirect } from 'react-admin';


const options = (record, notify, redirect) => {
    var buttonLabel;
    if(record.enabled) buttonLabel = 'disabled';
    else buttonLabel = 'enabled';

    return {
        undoable: false,
        onSuccess: ({ data }) => {
            // redirect('/comments');
            notify('Element ' + buttonLabel);
        },
        onFailure: (error) => notify('Error: Element not '+ buttonLabel, 'warning'),
    };

};

export const EnableToggleButton = (props) => {
        const notify = useNotify();
        const redirect = useRedirect();

        var {record, resource} = props;
        const payload = { id: record.id, previousData:record, data: { enabled: !record.enabled }  };

        var buttonLabel;
        if(record.enabled) buttonLabel = 'Disable';
        else buttonLabel = 'Enable';

        const [onClick, { loading }] = useMutation(
            {
                type: 'update',
                resource: resource,
                payload: payload,
            },
            options(record,notify,redirect)
        );

        return (<Button label={buttonLabel} onClick={onClick} disabled={loading}  />);

};
