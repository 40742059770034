const accounts = {
    name: 'Account |||| Accounts',
    fields: {
        name: 'Name',
        profileId: 'Profile Id',
        stock: 'Nb in stock',
        color: 'Color',
    },
};

const profiles = {
    name: 'Profile |||| Profiles',
    fields: {
        profileId: 'Profile Id',
        stock: 'Nb in stock',
        color: 'Color',
    },
};

export const en = {
    account: 'Account',
    fundTransferDate: 'Fund Transfer Date',
    originalTotalAmount: 'Original Amount',
    reserveAmount: 'Reserve Amount',
    processingStatus: 'Processing Status',
    fundTransferStatus: 'Fund Transfer Status',
    accountTail: 'Account Last Digits',
    snapshotDateTime: 'Date',
    sellableInventoryValue: 'Sellable Inventory Value',
    inTransitInventoryValue: 'In Transit Inventory Value',
    sellableTotalQuantity: 'Sellable Total Quantity',
    inTransitTotalQuantity: 'In Transit Total Quantity',
    startDate: 'Start Date',
    endDate: 'End Date',
    defectPercentage: 'Defect Percentage',
    defectCount: 'Defect Count',
    totalCount: 'Total Count',
    usergroups: 'User Groups',
    groups: 'Groups',
    username: 'User Name',
    name: 'Name',
    roles: 'Roles',
    email: 'Email',
    plainPassword: 'Password',
    resources: {
        accounts,
        profiles,
        customer: {
            name: 'Customer |||| Customers',
            fields: {
                first_name: 'First name',
                last_name: 'Last name',
                dob: 'Date of birth',
            }
        }
    },
};
