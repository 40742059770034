import React, { Fragment }   from "react";
import { List,
    Datagrid,EditButton, DeleteButton
} from 'react-admin';
import {ListActions} from "UI/Common/ListAction";
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import {FilterFinal} from "./Filter";
import {BulkActionButtons} from "./BulkAction";
import InlineEditButton from 'UI/Common/InlineEditButton';
import {EditFinal} from "./Edit";
import {useResourceOptions} from "../../hooks";
import {EnableToggleButton} from "../Common/EnableDisableButton";

export const ListPlain = ({...props }) => {
    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List actions={<ListActions/>}  filters={false} bulkActionButtons={<BulkActionButtons operations={operations} /> }  {...props}>
                <Datagrid>
                    {readableFields.id && <FieldGuesser label={'id'} source={'id'}/>}
                    {readableFields.title && <FieldGuesser label={'title'} source={'title'}/>}
                    {readableFields.abstract && <FieldGuesser label={'abstract'} source={'abstract'}/>}
                    <EnableToggleButton/>

                    {/*<InlineEditButton EditComponent={EditFinal}/>*/}
                    <EditButton/>
                    <DeleteButton/>

                </Datagrid>
            </List>
        </Fragment>
    );
};

export const ListFinal = ListPlain;
