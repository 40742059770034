import {
    Button,
    Grid,
    Paper,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useRef} from 'react';
import { Form,Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import {useMutation, CRUD_CREATE, useNotify} from 'react-admin';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subscription: {
            marginTop: theme.spacing(3),
            padding: theme.spacing(3),
        },
        wrap: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
    })
);


export const ScanForm = () => {
    const classes = useStyles();

    return (
        <div className={classes.wrap}>
            <MainForm/>
        </div>
    );
};


const useFormStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            marginTop: theme.spacing(3),
            padding: theme.spacing(3),
            marginBottom: theme.spacing(5),
        },
        paperInner: {
            marginLeft: theme.spacing(3),
            marginTop: theme.spacing(3),
            padding: theme.spacing(3),
        },
        buttons: {
            '& > ': {
                marginTop: theme.spacing(3),
                marginRight: theme.spacing(1),
            },
        },
    })
);

const initialValues = {
    personId: '',
};

const MainForm = ()  => {
    const classes = useFormStyles();
    const [mutate, { data, loading }] = useMutation();
    const notify = useNotify();
    const skuRef:any = useRef();
    const onSubmit = async (values,form) => {
        // return;
        await mutate (
            {
                type: 'create',
                resource: 'consulatereports',
                payload: {data: { ...values } }
            },
            {
                undoable: false,
                action: CRUD_CREATE,
                onSuccess: response => {
                    form.reset({...values});
                    skuRef.current.focus();
                    // notify('Success !')
                },
                onFailure: error => {
                    form.reset({ ...values});
                    skuRef.current.focus();
                    notify('Failure !')
                }
            }
        );
        return undefined;
    };

    return (
        <Paper className={classes.paper}>
            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                subscription={{ dirtySinceLastSubmit: true, submitting: true, pristine: true, touched: true }}
                // validate={validate}
                render={({ form, handleSubmit, submitting, ...rest }) => {
                    return (
                        <form
                            onSubmit={handleSubmit}
                            noValidate={true}>
                            <Grid container spacing={4}>
                                    <Grid item>
                                        <Field
                                            // fullWidth
                                            required
                                            name="personId"
                                            component={TextField}
                                            type="text"
                                            label="ID"
                                            autoFocus
                                            inputRef={skuRef}
                                            autoComplete={'off'}
                                        />
                                    </Grid>
                                    <Grid item className={classes.buttons}>
                                        <Button variant="contained" color="primary" type="submit" disabled={submitting || loading}>
                                            Submit
                                        </Button>
                                    </Grid>

                            </Grid>
                        </form>
                    )
                }}
            />
            {data && data.responseCsgn && <ProductInfo product={data.responseCsgn} type={'Consulta para migrantes'}/>}
            {data && data.responseMigr && <ProductInfo product={data.responseMigr} type={'Consulta para demás usuarios Courier'}/>}
        </Paper>
    );
};


const ProductInfo = ({product, type}) => {
    return (
        <>
            <table>
                <caption><b>{type}</b></caption>
                <tr>
                    <td>Fecha de consulta:</td>
                    <td>{product.fecha_consulta[0]}</td>
                </tr>
                <tr>
                    <td>Cédula</td>
                    <td>{product.ciudadano.cedula[0]}</td>
                </tr>
                <tr>
                    <td>Nombre</td>
                    <td>{product.ciudadano.nombre[0]}</td>
                </tr>
                <tr>
                    <td>Mensaje</td>
                    <td>{product.mensaje_ecuapass}</td>
                </tr>
            </table>
        </>
    )
};
