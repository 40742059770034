import React, { Fragment }   from "react";
import { List,
    Datagrid
} from 'react-admin';
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import {FilterFinal} from "./Filter";
import {useResourceOptions} from "../../hooks";
import {DefaultListProps} from "../Common/DefaultListProps";
import {EnableToggleButton} from "../Common/EnableDisableButton";

export const ListPlain = ({...props }) => {
    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List filters={<FilterFinal/>} {...DefaultListProps({operations})} {...props}>
                <Datagrid>
                    {readableFields.name && <FieldGuesser link={false} source={'name'}/>}
                    {readableFields.enabled && <FieldGuesser source={'enabled'}/>}
                    <EnableToggleButton/>
                    {/*<InlineEditButton EditComponent={EditFinal}/>*/}
                    {/*<EditButton/>*/}
                </Datagrid>
            </List>
        </Fragment>
    );
};

export const ListFinal = ListPlain;
