import React, { Fragment }   from "react";
import { List,
    Datagrid,
} from 'react-admin';
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import {FilterFinal} from "./Filter";
import {useResourceOptions} from "../../hooks";
import {DefaultListProps} from "../Common/DefaultListProps";
import {DateField} from "../Common/DateField";

export const ListPlain = ({...props }) => {
    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List  filters={<FilterFinal readableFields={readableFields}/>} {...DefaultListProps({operations})} {...props} bulkActionButtons={false}>
                <Datagrid>
                    {readableFields.childASIN && <FieldGuesser label={'Child ASIN'} source={'childASIN'}/>}
                    {readableFields.parentASIN && <FieldGuesser label={'Parent ASIN'} source={'parentASIN'}/>}
                    {<DateField showTime={false}  source={'reportDate'}/>}
                    {readableFields.title && <FieldGuesser  source={'title'}/>}
                    {<FieldGuesser  source={'sessions'}/>}
                    {<FieldGuesser  source={'sessionPercentage'}/>}
                    {<FieldGuesser  source={'pageViews'}/>}
                    {<FieldGuesser  source={'pageViewsPercentage'}/>}
                    {<FieldGuesser  source={'buyBoxPercentage'}/>}
                    {<FieldGuesser  source={'unitsOrdered'}/>}
                    {<FieldGuesser  source={'unitsOrderedB2B'}/>}
                    {<FieldGuesser  source={'unitSessionPercentage'}/>}
                    {<FieldGuesser  source={'unitSessionPercentageB2B'}/>}
                    {<FieldGuesser  source={'orderedProductSales'}/>}
                    {<FieldGuesser  source={'orderedProductSalesB2B'}/>}
                    {<FieldGuesser  source={'totalOrderItems'}/>}
                    {<FieldGuesser  source={'totalOrderItemsB2B'}/>}
                </Datagrid>
            </List>
        </Fragment>
    );
};

export const ListFinal = ListPlain;
