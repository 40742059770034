import React  from "react";
import { Show,
    TabbedShowLayout,
    Tab,
} from 'react-admin';
import {useResourceOptions} from "../../hooks";
import {Title} from "../Common/Title";
import {adsschedule, adsschedulelog} from "../../resources";
import {ScheduleLogList} from "../adsschedulerlog/List";

export const ShowFinal = (props) =>
{
    const { readableFields, operations } = useResourceOptions(props.resource);
    return (
        <Show title={<Title resource={adsschedule.name}/>} {...props}>
            <TabbedShowLayout>
                <Tab label="Schedule">
                    <ScheduleLogList targetResource={adsschedulelog.name} recordType={'scheduler'} location={props.location} id={props.id} match={props.match}/>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};
