import React  from "react";
import {Edit, Create } from 'react-admin';
import InputGuesser from 'UI/Common/ApiPlateform/InputGuesser';
import {useResourceOptions} from "../../hooks";

import {
    TabbedForm,
    FormTab,
} from 'react-admin'

const SimpleFormCommon = ({...props}) => {
    const { writableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.edit) return null;
    return (
        <>
            {writableFields.description && <InputGuesser source='description' label='description' {...props}/>}

        </>
    )
};

export const EditFinal = (props) => {
    return (
        <Edit undoable={false} {...props }>
            <TabbedForm>
                <FormTab label="summary">
                    <SimpleFormCommon/>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export const CreateFinal = ({...props}) => {
    return (
        <Create undoable={false} {...props}>
            <TabbedForm>
                <FormTab label="summary">
                    <SimpleFormCommon/>
                </FormTab>
            </TabbedForm>
        </Create>
    );
};
