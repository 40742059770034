import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import {
  channel,
  user,
  usergroup,
  financialEventGroup,
} from "../resources";

const options = [
  {
    resource: channel.name,
    key: channel.name,
    // leftIcon: CloudUploadIcon,
  },
  {
    resource: financialEventGroup.name,
    key: financialEventGroup.name,
    // leftIcon: CloudUploadIcon,
  },
  {
    key: 'setting-menu',
    label: 'Settings',
    leftIcon: <SettingsIcon/>,
    children: [
      {
        resource: user.name,
      },
      {
        resource: usergroup.name,
      },
    ],
  },
];
export default options;
