import React, { Fragment }   from "react";
import { List,
    Datagrid,
} from 'react-admin';
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import {FilterFinal} from "./Filter";
import InlineEditButton from 'UI/Common/InlineEditButton';
import {EditFinal} from "./Edit";
import {useResourceOptions} from "../../hooks";
import {DefaultListProps} from "../Common/DefaultListProps";
import {EnableToggleButton} from "../Common/EnableDisableButton";
import {CurrencyField} from "../Common/Currency";
import {SetDefaultButton} from "../Common/SetDefaultButton";
import {NameAsButton} from "../Common/Amazon";
import {ScheduleList} from "../adsscheduler/List";

export const ListPlain = ({...props }) => {
    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List perPage={100} filters={<FilterFinal/>} {...DefaultListProps({operations})} {...props}>
                <Datagrid expand={<ScheduleList recordType={'profile'}/>}>
                    {readableFields.name && <NameAsButton source={'name'}/>}
                    {readableFields.schedulersCount && <FieldGuesser label={'count'}  source={'schedulersCount'}/>}
                    {readableFields.account && <FieldGuesser link={false} label={'account'} source={'account.name'}/>}
                    {/*{readableFields.profileId && <FieldGuesser source={'profileId'}/>}*/}
                    {/*{readableFields.countryCode && <FieldGuesser source={'countryCode'}/>}*/}
                    {readableFields.currencyCode && <FieldGuesser source={'currencyCode'}/>}
                    {readableFields.dailyBudget && <CurrencyField source={'dailyBudget'}/>}
                    {readableFields.accountId && <FieldGuesser source={'accountId'}/>}
                    {readableFields.enabled && <FieldGuesser source={'enabled'}/>}
                    <EnableToggleButton/>
                    <SetDefaultButton/>
                    <InlineEditButton EditComponent={EditFinal}/>

                </Datagrid>
            </List>
        </Fragment>
    );
};

export const ListFinal = ListPlain;
