import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import {makeStyles, useMediaQuery} from '@material-ui/core';
import { useTranslate, DashboardMenuItem, MenuItemLink } from 'react-admin';
import {useMenuOptions} from './useMenuOptions';
import SidebarMenu from './SidebarMenu';
import classnames from 'classnames';

const useStyles = makeStyles({
    main: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
});

const Menu = ({ classes: classesOverride,
                  className,
                  dense,
                  hasDashboard,
                  onMenuClick,
                  logout,
                  ...rest
}) => {

    const classes = useStyles({ classes: classesOverride });
    const translate = useTranslate();
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);

    useSelector(state => state.theme); // force rerender on theme change
    useSelector(state => state.router.location.pathname); // used to force redraw on navigation
    const options = useMenuOptions();

    const initialState = {};
    var option;
    for (option of options){
        if(option) initialState[option.key] = false;
    }
    
    const [state, setState] = useState(initialState);

    const handleToggle = menu => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    const submenuStyle = '';
    const submenuColor = '';

    return (
        <div className={classnames(classes.main, className)} {...rest}>

            {hasDashboard && (
                <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
            )}

            {options.map(singleOption => {
                if(!singleOption) return null;
                return (
                    <SidebarMenu
                        key={singleOption.key}
                        submenuStyle={submenuStyle}
                        submenuColor={submenuColor}
                        singleOption={singleOption}
                        onMenuClick={onMenuClick}
                        dense={dense}
                        handleToggle={handleToggle}
                        isOpen={state[singleOption.key]}
                    />
                );
            })}

            {isXsmall && (
                <MenuItemLink
                    to="/profile/me"
                    primaryText={'profile'}
                    leftIcon={<SettingsIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            )}
            {isXsmall && logout}
        </div>
    );
};

Menu.propTypes = {
    onMenuClick: PropTypes.func,
    logout: PropTypes.object,
};

export default Menu;
