import supplierproduct from "./supplierproduct";
import channelproduct from "./channelproduct";
import channelsetting from "./channelsetting";
import suppliersetting from "./suppliersetting";
import product from "./product";
import channel from "./channel";
import supplier from "./supplier";
import supplierAttributes from "./supplierattribute";
import cronlog from "./cronlog";
import channelrequestlog from "./channelrequestlog";
import order from "./order";
import feed from "./Feed";
import warehouse from "./warehouse";
import locationtype from "./locationtype";
import location from "./location";
import productstock from "./productstock";
import productstocktransfer from "./productstocktransfer";
import transferstatus from "./transferstatus";
import productstocklog from "./productstocklog";
import study from "./study";
import author from "./author";
import studytype from "./studytype";
import user from "./user";
import usergroup from "./usergroup";
import roles from "./roles";
import profile from "./profile";
import adsaccount from "./adsaccount";
import adscampaign from "./adscampaign";
import adsportfolio from "./adsportfolio";
import adsprofile from "./adsprofile";
import adsscheduler from "./adsscheduler";
import adsschedulerlog from "./adsschedulerlog";
import amazonFinancialEventGroup from "./amazonFinancialEventGroup";
import inventoryLog from "./inventoryLog";
import amazonperformance from "./amazonperformance";
import adconversion from "./adconversion";
import adconversionreport from "./adconversionreport";
import channelstat from "./channelstat";
import amazonDailyDisbursement from "./amazonDailyDisbursement";
import channelpermissions from "./channelpermissions";
import walmartproduct from "./walmartproduct";
import walmartsearch from "./walmartsearch";
import shipment_batch from "./shipment_batch";
import {businessReportByParentResource} from "./adconversion";

export const resourceUIMapping = [
    product,
    channel,
    supplier,
    channelproduct,
    supplierproduct,
    channelsetting,
    suppliersetting,
    supplierAttributes,
    cronlog,
    channelrequestlog,
    order,
    feed,
    warehouse,
    locationtype,
    location,
    productstock,
    productstocktransfer,
    transferstatus,
    productstocklog,
    study,
    author,
    studytype,
    user,
    roles,
    usergroup,
    profile,
    adsportfolio,
    adscampaign,
    adsprofile,
    adsaccount,
    adsscheduler,
    adsschedulerlog,
    amazonFinancialEventGroup,
    amazonperformance,
    inventoryLog,
    adconversion,
    adconversionreport,
    channelstat,
    amazonDailyDisbursement,
    businessReportByParentResource,
    channelpermissions,
    walmartproduct,
    walmartsearch,
    shipment_batch,
];
