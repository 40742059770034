import React  from "react";
import {
    Filter,
    TextInput,
} from 'react-admin';

export const FilterFinal = ({readableFields, ...props}) => {
    const choices = [
        {value: 'enabled', name: 'enabled'},
        {value: 'disabled', name: 'disabled'},
    ];
    return (
        <Filter {...props}>
            <TextInput source="usItemId" label={'Walmart Item Id'} alwaysOn/>
        </Filter>
    )
};
