import {ListFinal} from "./List";
import {ShowFinal} from "./Show";
import {EditFinal,CreateFinal} from "./Edit";

export default {
    name:   'channelproducts',
    list:   ListFinal,
    edit:   EditFinal,
    create:   CreateFinal,
    show:   ShowFinal,
    options:   { label: 'Channel Products' },
};
