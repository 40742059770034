import {shallowEqual, useSelector} from 'react-redux';

// import options from "./options";
import options from "./options_invoice_bazar";
// import options from "./options_walmart_search";
// import options from "./options_dayparting";
// import options from "./options_walmart_search";
// import options from "./options_mslib";

export const useMenuOptions = () => {
    const resources = useSelector(state =>
    {
        return  state.admin.resources;

    }, shallowEqual);

    return options.map(singleOption => {
        var newOption = {...singleOption};
        if (singleOption.children) {
            var childExists = false;
            newOption.children = singleOption.children.map(child => {
                let finalChild = processChild(child);
                if(finalChild) childExists = true;
                return finalChild;
            });
            if(!childExists) return null;

        } else newOption = processChild(singleOption);
        return newOption;

    });

    function processChild(child) {
        var newChild = {...child};
        if(!child.resource) return newChild;
        var resource = resources[child.resource];
        if(!resource) newChild = null;
        else if(!resource.props.hasList) newChild = null;
        else if(resource.props.hasList) newChild.resource = resource.props;
        return newChild;
    }

};
