import React, { Fragment }   from "react";
import { List,
    Datagrid,ShowButton
} from 'react-admin';
import {ListActions} from "UI/Common/ListAction";
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import {FilterFinal} from "./Filter";
import {BulkActionButtons} from "./BulkAction";
import InlineEditButton from 'UI/Common/InlineEditButton';
import {EditFinal} from "./Edit";
import {useResourceOptions} from "../../hooks";
import {EnableToggleButton} from "../Common/EnableDisableButton";
import {QuickUpdateButton} from "../Common/QuickUpdateButton";

export const ListPlain = ({...props }) => {
    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List title={'Stores'} actions={<ListActions disableExport={true}/>}  filters={<FilterFinal/>} bulkActionButtons={<BulkActionButtons operations={operations} /> }  {...props}>
                <Datagrid>
                    {/*{readableFields.code && <FieldGuesser label={'code'} source={'code'}/>}*/}
                    {readableFields.name && <FieldGuesser label={'name'} source={'name'}/>}
                    {operations.edit && <RecordAwareComponent Component={EnableToggleButton}/>}
                    {operations.view && <ShowButton/>}
                    {operations.edit && <RecordAwareComponent Component={InlineEditButton} EditComponent={EditFinal}/>}
                    {operations.edit && <CronButton data={{name: 'financial'}} label={'Update Financial Data'}/>}
                </Datagrid>
            </List>
        </Fragment>
    );
};

const CronButton = ({data,record, ...props}) => {
    const newData = {...data }
    newData.channel = record.id;
    return (
        <RecordAwareComponent {...props} record={record} Component={QuickUpdateButton} resource={'channels/cronstart'} data={newData} failedMessage={'cron failed'} successMessage={'cron started'} label={'Update Financial Data'}/>
    )
}

const RecordAwareComponent = ({Component, ...props}) => {
    const {record} = props;
    return (
            <>
                {record.owner && <Component {...props}/>}
            </>
    );
}

export const ListFinal = ListPlain;
