import React  from "react";

import { Show,
    TabbedShowLayout,
    Tab,
} from 'react-admin';
import {ScheduleList} from "../adsscheduler/List";
import {CreateSchedule} from "../adsscheduler/Edit";
import {useResourceOptions} from "../../hooks";
import {Title} from "../Common/Title";
import {adscampaign} from "../../resources";

export const ShowFinal = (props) =>
{
    const { readableFields, operations } = useResourceOptions(props.resource);
    return (
        <Show title={<Title resource={adscampaign.name}/>} {...props}>
            <TabbedShowLayout>
                <Tab label="Schedule">
                    <ScheduleList recordType={'portfolio'} location={props.location} id={props.id} match={props.match}/>
                </Tab>
                <Tab label="Add Schedule">
                    <CreateSchedule recordType={'portfolio'}/>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};
