import React  from "react";

import {
    CreateButton,
    TopToolbar,
    RefreshButton,
} from 'react-admin';
import {ExportButton} from "UI/Common/ExportButton";

export const ListActions = ({
                                bulkActions,
                                basePath,
                                currentSort,
                                displayedFilters,
                                exporter,
                                filters,
                                filterValues,
                                onUnselectItems,
                                resource,
                                selectedIds,
                                showFilter,
                                hasCreate,
                                total, ...rest
                            }) => {
    return (
        <TopToolbar>
            {filters && React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            }) }
            {hasCreate && <CreateButton basePath={basePath} />}
            {!rest.disableExport && <ExportButton/>}
            <RefreshButton />
        </TopToolbar>
    );
}
