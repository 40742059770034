import React, { Fragment }   from "react";
import { List,
    Datagrid
} from 'react-admin';
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import {FilterFinal} from "./Filter";
import InlineEditButton from 'UI/Common/InlineEditButton';
import {EditFinal} from "./Edit";
import {useResourceOptions} from "../../hooks";
import {CurrencyField} from "../Common/Currency";
import {DefaultListProps} from "../Common/DefaultListProps";
import {AmazonLink} from "../Common/Amazon";
import {EnableToggleButton} from "../Common/EnableDisableButton";

export const ListPlain = ({...props }) => {
    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List filters={<FilterFinal/>} {...DefaultListProps({operations})} {...props}>
                <Datagrid>
                    {readableFields.supplier && <FieldGuesser link={false} label={'supplier'} source={'supplier'}/>}
                    {readableFields.supplierProductId && <FieldGuesser label={'supplierProductId'} source={'supplierProductId'}/>}
                    {readableFields.supplierProductId && <AmazonLink label={'ASIN'} source={'product.asin'} domain={'com'}/>}
                    {readableFields.supplierProductId && <FieldGuesser label={'UPC'} source={'product.upc'}/>}
                    {readableFields.stock && <FieldGuesser label={'stock'} source={'stock'}/>}
                    {readableFields.price && <CurrencyField label={'price'} source={'price'}/>}
                    {readableFields.finalShippingPrice && <CurrencyField label={'finalShippingPrice'} source={'finalShippingPrice'}/>}
                    {readableFields.finalTotalCost && <CurrencyField label={'finalTotalCost'} source={'finalTotalCost'}/>}
                    <EnableToggleButton/>

                    <InlineEditButton EditComponent={EditFinal}/>
                    {/*<EditButton/>*/}

                </Datagrid>
            </List>
        </Fragment>
    );
};

export const ListFinal = ListPlain;
