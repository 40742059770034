import React  from "react";
import { Show,
    TabbedShowLayout,
    Tab,
} from 'react-admin';
import {useResourceOptions} from "../../hooks";
import {Title} from "../Common/Title";
import {productStockLog,product} from "../../resources";
import {SubResourceList} from "../Common/SubResourceList";
import {ListFinal as ProductStockLogList} from "../productstocklog/List"

export const ShowFinal = (props) =>
{
    const { readableFields, operations } = useResourceOptions(props.resource);
    return (
        <Show title={<Title resource={product.name}/>} {...props}>
            <TabbedShowLayout>
                <Tab label="Log">
                    <SubResourceList Component={ProductStockLogList} targetResource={productStockLog.name} recordType={'productStock'} location={props.location} id={props.id} match={props.match}/>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};
