import React  from "react";
import { Edit, Create,FormTab, TabbedForm} from 'react-admin';
import InputGuesser from 'UI/Common/ApiPlateform/InputGuesser';

const SimpleFormCommon = (props) => {
    return (
        <TabbedForm {...props}>
            <FormTab label="User">
                { <InputGuesser source='currentPassword' label='currentPassword'/>}
                { <InputGuesser source='username' label='username'/>}
                { <InputGuesser source='email' label='email'/>}
                { <InputGuesser source='plainPassword' label='plainPassword'/>}

            </FormTab>
        </TabbedForm>
    )
};


export const EditFinal = ({...props}) => {
    return (
        <Edit undoable={false} {...props }>
            <SimpleFormCommon {...props}/>
        </Edit>
    );
};

export const CreateFinal = ({...props}) => {
    return (
        <Create undoable={false} {...props}>
            <SimpleFormCommon/>
        </Create>
    );
};
