import actions from './actions';

const initState = null;

export default function profileReducer(state = initState, action) {
  switch (action.type) {
    case actions.PROFILE_CHANGED:
      return action.payload.id;
    default:
      return state;
  }
}
