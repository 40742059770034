import React  from 'react';
import {  UserMenu, MenuItemLink } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const MyUserMenuView = (props) => {
    return (
        <UserMenu label={'me'} icon={<AccountCircleIcon/>} {...props}>
            <MenuItemLink
                to="/profile/me"
                primaryText="My profile"
                leftIcon={<SettingsIcon />}
            />
        </UserMenu>
    );
};

export default MyUserMenuView;
