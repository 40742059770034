import React  from "react";
import {Edit, Create,FormTab,TabbedFormTabs, ArrayInput, SimpleFormIterator, TextInput} from 'react-admin';
import InputGuesser from 'UI/Common/ApiPlateform/InputGuesser';
import TabbedForm from 'UI/Common/TabbedForm';
import {useResourceOptions} from "../../hooks";
import RichTextInput from 'ra-input-rich-text';
import {cloneDeepWith} from 'lodash';

function customizer(value) {
    if(value['@type'] === 'studytype') return value.id;
}

const SimpleFormCommon = ({record, ...props}) => {
    var newRecord = cloneDeepWith(record, customizer);
    const { writableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.edit) return null;


    return (
        <TabbedForm record={newRecord} verticalTabsClassName={'vertical-tabbed-form'} tabs={<TabbedFormTabs orientation="vertical"/>} {...props}>
            <FormTab label="Type">
                {writableFields.type && <InputGuesser source='type' optionText={'name'} label='Type'/>}
            </FormTab>
            <FormTab label="Title">
                {writableFields.title && <InputGuesser multiline source='title' label='Title'/>}
            </FormTab>

            <FormTab label="Author">
                <ArrayInput source="authors">
                    <SimpleFormIterator>
                        <TextInput multiline label={'Name'} source="name" />
                        <TextInput multiline label={'Qualification'} source="qualification" />
                        <TextInput multiline label={'Institution'} source="institution" />
                        <TextInput multiline label={'Email'} source="emailId" />
                        <TextInput multiline label={'Address'} source="address" />
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label="Abstract">
                {writableFields.abstract && <RichTextInputWithToolBar source='abstract' label='Abstract'/>}
            </FormTab>
            <FormTab label="Keywords">
                <ArrayInput source="keywords">
                    <SimpleFormIterator>
                        <TextInput multiline/>
                    </SimpleFormIterator>
                </ArrayInput>

            </FormTab>
            <FormTab label="Introduction">
                {writableFields.introduction && <RichTextInputWithToolBar source='introduction' label='Introduction'/>}
            </FormTab>
            <FormTab label="Discussion">
                {writableFields.discussion && <RichTextInputWithToolBar source='discussion' label='Discussion'/>}
            </FormTab>
            <FormTab label="Conclusion">
                {writableFields.conclusion && <RichTextInputWithToolBar source='conclusion' label='Conclusion'/>}
            </FormTab>
            <FormTab label="References">
                <ArrayInput source="references">
                    <SimpleFormIterator>
                        <TextInput multiline label={'Content'} source="content" />
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label="Consent">
                {writableFields.consent && <RichTextInputWithToolBar source='consent' label='Consent'/>}
            </FormTab>
            <FormTab label="Acknwoledgemment">
                {writableFields.acknwoledgemment && <RichTextInputWithToolBar source='acknwoledgemment' label='Acknwoledgemment'/>}
            </FormTab>
            <FormTab label="Source Of Funding">
                {writableFields.introduction && <RichTextInputWithToolBar source='sourceOfFunding' label='Source Of Funding'/>}
            </FormTab>
        </TabbedForm>

    )
};

export const RichTextInputWithToolBar = (props) => {
    var toolbarOptions = [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
    ];

    return (
        <RichTextInput toolbar={toolbarOptions} {...props}/>
    )
};

export const EditFinal = ({...props}) => {
    return (
        <Edit undoable={false} {...props }>
            <SimpleFormCommon {...props}/>
        </Edit>
    );
};

export const CreateFinal = ({...props}) => {
    return (
        <Create undoable={false} {...props}>
            <SimpleFormCommon/>
        </Create>
    );
};
