import React from "react";
import Typography from "@material-ui/core/Typography";

export const HourMin = ({ record }) => {
    var d = new Date();
    if(record.min) d.setUTCHours(record.hour[0], record.min[0]);
    // if(record.min) d.setMinutes(record.min[0]);
    // if(record.hour) d.setHours(record.hour[0]);
    const options = {hour12: true, minute: '2-digit', hour: '2-digit' };
    return <Typography component="span" variant="body2">{d.toLocaleTimeString('en-GB', options)}</Typography>
};
