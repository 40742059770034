import React  from "react";
import {
    Filter,
    TextInput,
    SelectArrayInput,
} from 'react-admin';


export const FilterFinal = (props) => {
    const choices = [
        {value: 'enabled', name: 'enabled'},
        {value: 'disabled', name: 'disabled'},
    ];
    return (
        <Filter {...props}>
            <TextInput source="name" label={'name'} alwaysOn/>
            <SelectArrayInput allowEmpty={true} source="state" choices={choices} optionText="name" optionValue="value" alwaysOn/>
            {/*<TextInput source="campaignId" label='campaign id' alwaysOn />*/}
        </Filter>
    )
};
