import React  from "react";

import {
    Filter,
    TextInput,
} from 'react-admin';


export const FilterFinal = (props) => (
    <Filter {...props}>
        <TextInput label="barcode" source="code" alwaysOn/>
        <TextInput label="location" source="location" alwaysOn/>
    </Filter>
);
