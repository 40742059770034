import React  from "react";

import { List,
    Datagrid,
    TextField
} from 'react-admin';
import { withStyles, createStyles } from '@material-ui/core/styles';
import {DateField} from "UI/Common/DateField";
import {Button} from "@material-ui/core";
import {useResourceOptions} from "hooks/userResource";

const listStyles = function (theme) {
    return createStyles({
    });
};


export const FeedDownloadButton = ({record, source, fileName = 'fileName'}) => {
    var link = record[source];
    if(!record[source]) return null;
    return (
        <Button target={'_blank'} href={link} color="primary" label={record[fileName]} variant='text'>{record[fileName]}
        </Button>
    );
}

export const ListPlain = ({classes, ...rest }) => {

    const { readableFields, operations } = useResourceOptions(rest.resource);
    if(!operations || !operations.list) return null;

    return (
        <React.Fragment>
            <List {...rest} bulkActionButtons={false} >
                <Datagrid classes={classes}>
                    {readableFields.fileName && <FeedDownloadButton label={'File Name'} source={'downloadUrl'}/>}
                    {readableFields.feedType && <TextField label={'Type'} source={'feedType'}/>}
                    {readableFields.fileSize && <TextField label={'Size'} source={'fileSize'}/>}
                    {readableFields.totalRecords && <TextField label={'Total'} source={'totalRecords'}/>}
                    {readableFields.totalSuccess && <TextField label={'Success'} source={'totalSuccess'}/>}
                    {readableFields.totalFail && <TextField label={'Fail'} source={'totalFail'}/>}
                    {readableFields.totalWarning && <TextField label={'Warning'} source={'totalWarning'}/>}
                    {readableFields.processingStatus && <TextField label={'Status'} source={'processingStatus'}/>}
                    <DateField label={'Uploaded On'} source="uploadedAt"/>
                    <DateField label={'Updated at'} source="updatedAt"/>
                </Datagrid>
            </List>
        </React.Fragment>
    );
};

export const ListFinal = withStyles(listStyles)(ListPlain);

