import React  from "react";
import {
    Filter,
    TextInput,
    SelectArrayInput,
} from 'react-admin';
import {DateInput} from "../Common/DatePicker";


export const FilterFinal = ({readableFields, ...props}) => {
    const choices = [
        {value: 'enabled', name: 'enabled'},
        {value: 'disabled', name: 'disabled'},
    ];
    return (
        <Filter {...props}>
            {readableFields.childASIN && <TextInput source="childASIN" label={'Child ASIN'} alwaysOn/>}
            <TextInput source="parentASIN" label={'Parent ASIN'} alwaysOn/>
            <DateInput source="reportDate.after" label={'Date From'} alwaysOn/>
            <DateInput source="reportDate.before" label={'Date To'} alwaysOn/>
            {/*<SelectArrayInput allowEmpty={true} source="state" choices={choices} optionText="name" optionValue="value" alwaysOn/>*/}
            {/*<TextInput source="campaignId" label='campaign id' alwaysOn />*/}
        </Filter>
    )
};
