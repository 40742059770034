import React from "react";
import {
    DateField as BaseDateField,
} from 'react-admin';

export const DateField = (props) =>{
    var {record,label,source,locales,showTime} = props;
    if(!locales){
        if(record.store && record.store.currency === 'GBP') locales = 'en-GB';
        else locales = 'fr-FR';
    }
    locales = 'en-US';
    const options = {dateStyle:'long'};
    if(showTime) options.timeStyle = 'medium';

    return (
        <BaseDateField record={record} label={label} source={source} showTime={showTime} locales={locales} options={options}/>

    )
};
