import React, { Fragment }   from "react";
import {
    Datagrid,
    ArrayField,
    SingleFieldList,
    useChoices,
    DeleteButton,
    ShowButton,
} from 'react-admin';
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import InlineEditButton from 'UI/Common/InlineEditButton';
import {EditFinal} from "./Edit";
import {List} from "../Common/List";
import {useResourceOptions} from "../../hooks";
import {DefaultListProps} from "../Common/DefaultListProps";
import {EnableToggleButton} from "../Common/EnableDisableButton";
import {HourMin} from "../Common/HourMin";
import {CurrencyField} from "../Common/Currency";
import {weekDays} from "../../scheduler/weekDays";
import Chip from "@material-ui/core/Chip";
import {adsschedule, adsschedulelog} from "../../resources";
import {ScheduleLogList} from "../adsschedulerlog/List";

const OwnField = ({choices, record, optionText, optionValue, translateChoice = false}) => {
    const { getChoiceText, getChoiceValue } = useChoices({
        optionText,
        optionValue,
        translateChoice,
    });

    const choice = choices.find(choice => getChoiceValue(choice) === record);
    let choiceText = getChoiceText(choice);
    return (
        <Chip label={choiceText}/>
    )
};

export const ListPlain = ({...props }) => {
    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List perPage={100} {...DefaultListProps({operations})} {...props}>
                <Datagrid>
                    {readableFields.name && <FieldGuesser label={'name'} source={'name'}/>}
                    <ArrayField source="dayOfWeek">
                        <SingleFieldList>
                            <OwnField  optionText="name" optionValue="value" choices={weekDays}/>
                        </SingleFieldList>
                    </ArrayField>
                    {readableFields.hour && <HourMin label={'Time'} source={'hour[0]'}/>}
                    {readableFields.budget && <CurrencyField label={'budget'} source={'budget'}/>}
                    {readableFields.nextRunTime && <FieldGuesser source={'nextRunTime'} showTime={true}/>}
                    {readableFields.note && <FieldGuesser source={'note'}/>}
                    {readableFields.enabled && <FieldGuesser source={'enabled'}/>}
                    <EnableToggleButton/>
                    <InlineEditButton EditComponent={EditFinal}/>
                    <InlineEditButton EditComponent={ScheduleLogList} targetResource={adsschedulelog.name} recordType={'scheduler'} label={'Log'}/>
                    <DeleteButton redirect={false} undoable={false}/>

                </Datagrid>
            </List>
        </Fragment>
    );
};

export const ListFinal = ListPlain;

export const ScheduleList = ({record, resource,basePath,id,recordType, ...props}) => {
    return (
        <ListFinal bulkActionButtons={false} component={'div'} resource={adsschedule.name} basePath={`/${adsschedule.name}`} filter={{[recordType]: record.id}}
                   hasCreate={false} hasEdit={false} hasShow={false} hasList={true}
                   {...props}/>
    );
};
