import React  from "react";
import {Edit, Create } from 'react-admin';
import InputGuesser from 'UI/Common/ApiPlateform/InputGuesser';
import {useResourceOptions} from "../../hooks";
import {CurrencyInputField} from "../Common/Currency";

import {
    TabbedForm,
    FormTab,
} from 'react-admin'

const SimpleFormCommon = ({...props}) => {
    const { writableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.edit) return null;
    return (
        <>
            {writableFields.supplier && <InputGuesser source='supplier' label='supplier' {...props}/>}
            {writableFields.supplierProductId && <InputGuesser source='supplierProductId' label='supplierProductId' {...props}/>}
            {writableFields.stock && <InputGuesser source='stock' label='stock' {...props}/>}
            {writableFields.price && <CurrencyInputField source='price' label='price' {...props}/>}
            {writableFields.price &&
            <InputGuesser source='product.upc' label='UPC' {...props}/>}
            {writableFields.price &&
            <InputGuesser source='product.asin' label='ASIN' {...props}/>}

        </>
    )
};

export const EditFinal = (props) => {
    return (
        <Edit undoable={false} {...props }>
            <TabbedForm>
                <FormTab label="summary">
                    <SimpleFormCommon/>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export const CreateFinal = ({...props}) => {
    return (
        <Create undoable={false} {...props}>
            <TabbedForm>
                <FormTab label="summary">
                    <SimpleFormCommon/>
                </FormTab>
            </TabbedForm>
        </Create>
    );
};
