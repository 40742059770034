export const weekDays = [
    {name: 'All', value: '*'},
    {name: 'MON', value: 1},
    {name: 'TUE', value: 2},
    {name: 'WED', value: 3},
    {name: 'THU', value: 4},
    {name: 'FRI', value: 5},
    {name: 'SAT', value: 6},
    {name: 'SUN', value: 0},

];
