import React, { Fragment }   from "react";
import {
    Datagrid,
} from 'react-admin';
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import {FilterFinal} from "./Filter";
import {useResourceOptions} from "../../hooks";
import {DefaultListProps} from "../Common/DefaultListProps";
import {List} from "../Common/List";

export const ListPlain = ({...props }) => {
    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List title={'report'} filters={<FilterFinal/>} {...DefaultListProps({operations})} {...props}>
                <Datagrid>
                    {readableFields.orders && <FieldGuesser label={'orders'}  source={'orders'}/>}
                    {readableFields.unitSold && <FieldGuesser label={'unitSold'}  source={'unitSold'}/>}
                    {readableFields.clicks && <FieldGuesser label={'clicks'}  source={'clicks'}/>}
                    {readableFields.conversionRate && <FieldGuesser label={'conversionRate'}  source={'conversionRate'}/>}
                    {readableFields.purchaseDate && <FieldGuesser label={'purchaseDate'}  source={'purchaseDate'}/>}
                </Datagrid>
            </List>
        </Fragment>
    );
};


export const AdConversionReportList = ({record,targetResource,resource,basePath,id,recordType, ...props}) => {
    return (
        <ListFinal bulkActionButtons={false} component={'div'} resource={targetResource} basePath={`/${targetResource}`} filter={{[recordType]: record.id}}
                   hasCreate={false} hasEdit={false} hasShow={false} hasList={true}
                   {...props}/>
    );
};


export const ListFinal = ListPlain;
