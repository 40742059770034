import React, {Fragment, useState} from 'react';
import { Button } from 'react-admin';
import Drawer from '@material-ui/core/Drawer';
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import EditIcon from '@material-ui/icons/Edit';
import { useLocation } from 'react-router-dom'

export const InlineEditButton = ({EditComponent,label, ...rest}) => {
    const [showPanel, setShowPanel] = useState(false);
    let location = useLocation();
    const handleClick = () => {
        setShowPanel(true);
    };
    const handleCloseClick = () => {
        setShowPanel(false);
    };

    return (
        <div>
            <Button label={label ?? 'Edit'} onClick={handleClick}>
                <EditIcon/>
            </Button>

            <Fragment>
                <Drawer
                    anchor="right"
                    open={showPanel}
                    onClose={handleCloseClick}
                >
                    <div>
                        <Button label="Close" onClick={handleCloseClick}>
                            <IconKeyboardArrowRight />
                        </Button>
                        {EditComponent && React.createElement(EditComponent,{id:rest.record.id, redirect: location.pathname, ...rest})}
                    </div>
                </Drawer>
            </Fragment>
        </div>
    );
};

export default InlineEditButton;
