import {roles as ResourceData} from "../../resources";

export default {
    name:   ResourceData.name,
    list:   false,
    edit:   false,
    create:   false,
    show:   false,
    options:   { label: ResourceData.label },
};
