import React from "react";
import {  Button } from 'react-admin';
import {removeProfileCookie, setProfileCookie} from "../../util/cookies";
import { useDispatch,useSelector } from 'react-redux'
import {profileChangedAction, profileSelector} from "../../Reducer/profile";

export const SetDefaultButton = ({record}) => {
    const dispatch = useDispatch();
    const profile = useSelector(profileSelector);
    const remove = 'Remove Default';
    const set = 'Set Default';
    const label = profile  === record.id ? remove: set;

    const setOnClick = () => {
        dispatch(profileChangedAction(record.id));
        setProfileCookie(record.id);
    };

    const removeOnClick = () => {
        dispatch(profileChangedAction(null));
        removeProfileCookie();
    };

    const onClick = profile  === record.id ? removeOnClick: setOnClick;

    return (<Button label={label} onClick={onClick}/>);

};
