import React from "react";

export const SubResourceList = ({record, filter, Component, targetResource, resource,basePath,id,recordType, ...props}) => {
    return (
        <Component
            bulkActionButtons={false}
            component={'div'}
            resource={targetResource}
            basePath={`/${targetResource}`}
            filter={{[recordType]: record.id, ...filter}}
            hasCreate={false} hasEdit={false} hasShow={false} hasList={true}
            {...props}/>
    );
};


export const FilteredResourceList = ({record,Component, targetResource, resource,basePath,id,recordType, ...props}) => {
    return (
        <Component
            bulkActionButtons={false}
            component={'div'}
            resource={targetResource}
            basePath={`/${targetResource}`}
            hasCreate={false} hasEdit={false} hasShow={false} hasList={true}
            {...props}/>
    );
};
