import React  from "react";
import {Edit, SimpleForm, Create, SimpleFormIterator, TextInput, ArrayInput} from 'react-admin';
import {useResourceOptions} from "../../hooks";
import {CurrencyInputField} from "../Common/Currency";
import InputGuesser from "../Common/ApiPlateform/InputGuesser";

const SimpleFormCommon = ({...props}) => {
    const { writableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.edit) return null;

    return (
        <SimpleForm {...props}>
            {writableFields.name && <InputGuesser source='name'/>}
            {/*{writableFields.dailyBudget && <CurrencyInputField source='dailyBudget'/>}*/}
            {writableFields.notificationEmails &&
                <ArrayInput source="notificationEmails">
                    <SimpleFormIterator>
                        <TextInput multiline/>
                    </SimpleFormIterator>
                </ArrayInput>
            }
        </SimpleForm>
    )
};

export const EditFinal = ({...props}) => {
    return (
        <Edit undoable={false} {...props }>
            <SimpleFormCommon/>
        </Edit>
    );
};

export const CreateFinal = ({...props}) => {
    return (
        <Create undoable={false} {...props}>
            <SimpleFormCommon/>
        </Create>
    );
};
