import React  from "react";

import {
    Filter,
    TextInput,
    SelectArrayInput,
} from 'react-admin';


export const FilterFinal = (props) => {
    const choices = [
        {value: 'USD', name: 'USD'},
        {value: 'CAD', name: 'CAD'},
    ];
    return (
        <Filter {...props}>
            <TextInput source="name" label={'name'} alwaysOn />
            <SelectArrayInput allowEmpty={true} source="currencyCode" choices={choices} optionText="name" optionValue="value" alwaysOn/>
            {/*<TextInput source="profileId" label='profile id' alwaysOn />*/}
            <TextInput source="accountId" label='Merchant Id' alwaysOn />
        </Filter>
    )
};
