import React  from "react";

import {
    Filter,
    TextInput,
} from 'react-admin';


export const FilterFinal = (props) => (
    <Filter {...props}>
        <TextInput label="name" source="name" alwaysOn={true}/>
    </Filter>
);
