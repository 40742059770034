import React, { Fragment }   from "react";
import {
    Datagrid,
    DeleteButton,
    ShowButton,
} from 'react-admin';
import {ListActions} from "UI/Common/ListAction";
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import {List} from "../Common/List";
import {BulkActionButtons} from "./BulkAction";
import InlineEditButton from 'UI/Common/InlineEditButton';
import {EditFinal} from "./Edit";
import {useResourceOptions} from "../../hooks";
import {EmptyTitle} from "../Common/Title";

export const ListPlain = ({productdata, ...props }) => {
    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List title={<EmptyTitle/>} actions={<ListActions/>}  filters={false} bulkActionButtons={<BulkActionButtons operations={operations} /> }  {...props}>
                <Datagrid>
                    {readableFields.product && productdata && <FieldGuesser label={'SKU'} source={'product.code'}/>}
                    {readableFields.product && productdata && <FieldGuesser label={'product'} source={'product.name'}/>}

                    {readableFields.location && <FieldGuesser label={'warehouse'} source={'location.warehouse.name'}/>}
                    {readableFields.location && <FieldGuesser label={'location'} source={'location.locationId'}/>}
                    {readableFields.location && <FieldGuesser label={'Type'} source={'location.locationType.name'}/>}
                    {readableFields.quantity && <FieldGuesser label={'quantity'} source={'quantity'}/>}
                    {/*<EnableToggleButton/>*/}

                    <InlineEditButton EditComponent={EditFinal}/>
                    <ShowButton label={'Log'} icon={false}/>
                    <DeleteButton undoable={false}/>

                </Datagrid>
            </List>
        </Fragment>
    );
};

export const ListFinal = ListPlain;
