import React from "react";
import {  Button } from 'react-admin';
import { useMutation, useNotify, useRedirect } from 'react-admin';


const options = ({record, successMessage, failedMessage, notify, redirect}) => {

    return {
        undoable: false,
        onSuccess: ({ data }) => {
            // redirect('/comments');
            notify('' + successMessage);
        },
        onFailure: (error) => notify('Error: '+ failedMessage, 'warning'),
    };

};

export const QuickUpdateButton = ({resource, record, data,successMessage,failedMessage, label, ...props}) => {
        const notify = useNotify();
        const redirect = useRedirect();

        const payload = { id: record.id + '/cronstart', previousData:record, data  };

        const [onClick, { loading }] = useMutation(
            {
                type: 'update',
                resource: resource,
                payload: payload,
            },
            options({successMessage, failedMessage, record,notify,redirect})
        );

        return (<Button label={label} onClick={onClick} disabled={loading}  />);

};

export const QuickCreateButton = ({resource, record, data,successMessage,failedMessage, label, ...props}) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const payload = { data  };

    const [onClick, { loading }] = useMutation(
        {
            type: 'create',
            resource: resource,
            payload: payload,
        },
        options({successMessage, failedMessage, notify,redirect})
    );

    return (<Button label={label} onClick={onClick} disabled={loading}  />);

};
