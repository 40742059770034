import React, { Fragment }   from "react";
import {
    Datagrid
} from 'react-admin';
import {ListActions} from "UI/Common/ListAction";
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import {FilterFinal} from "./Filter";
import {BulkActionButtons} from "./BulkAction";
import {useResourceOptions} from "../../hooks";
import {List} from "../Common/List";
import {EmptyTitle} from "../Common/Title";

export const ListPlain = ({...props }) => {
    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List title={<EmptyTitle/>} actions={<ListActions/>}  filters={<FilterFinal/>} bulkActionButtons={<BulkActionButtons operations={operations} /> }  {...props}>
                <Datagrid>
                    {readableFields.productStock && <FieldGuesser label={'SKU'} source={'productStock.product.code'}/>}
                    {readableFields.productStock && <FieldGuesser label={'Name'} source={'productStock.product.name'}/>}
                    {readableFields.newQuantity && <FieldGuesser label={'newQuantity'} source={'newQuantity'}/>}
                    {readableFields.oldQuantity && <FieldGuesser label={'oldQuantity'} source={'oldQuantity'}/>}
                    {readableFields.quantityChange && <FieldGuesser label={'quantityChange'} source={'quantityChange'}/>}
                    {readableFields.productStock && <FieldGuesser label={'BinRack'} source={'productStock.location.locationId'}/>}
                    {readableFields.createdBy && <FieldGuesser label={'User'} source={'createdBy.username'}/>}
                    {readableFields.createdAt && <FieldGuesser showTime={true} label={'Time'} source={'createdAt'}/>}
                    {readableFields.syncToLinnworksRequired && <FieldGuesser label={'Sync Required'} source={'syncToLinnworksRequired'}/>}
                    {readableFields.syncToLinnworksDone && <FieldGuesser label={'Sync Linnwork Done'} source={'syncToLinnworksDone'}/>}
                    {/*<EnableToggleButton/>*/}

                    {/*<InlineEditButton EditComponent={EditFinal}/>*/}
                    {/*<EditButton/>*/}

                </Datagrid>
            </List>
        </Fragment>
    );
};

export const ListFinal = ListPlain;
