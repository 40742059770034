import React from "react";
import { Button } from '@material-ui/core';
import get from 'lodash/get';
import {
    ShowButton,
} from 'react-admin';

export const AmazonLink = ({record, source, domain}) => {
    const field = get(record, source);
    var link = `https://www.amazon.${domain}/gp/offer-listing/${field}/ref=olp_f_usedLikeNew?ie=UTF8&f_new=true&f_usedLikeNew=true`;
    return (
        <Button href={link}
                color="primary" target="_blank" label={field} variant='text'>{field}
        </Button>
)};


export const NameAsButton = (props) => {
    const field = get(props.record, props.source);
    return (
        <ShowButton icon={false} variant='text' label={field} {...props}/>
    )
};
