import React  from "react";

import {
    Filter,
    TextInput,ReferenceInput,SelectInput
} from 'react-admin';

export const FilterFinal = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Channel" source="channel" reference="channels">
            <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput label="Order ID" source="channelOrderId"/>
        {/*<TextInput label="State" source="state"/>*/}
        {/*<TextInput label="UPC" source="product__upc"/>*/}
        {/*<TextInput label="ASIN" source="product__asin"/>*/}
    </Filter>
);
