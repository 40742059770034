import React, { Fragment }   from "react";
import {
    Datagrid
} from 'react-admin';
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import {List} from "../Common/List";
import {useResourceOptions} from "../../hooks";
import {EmptyTitle} from "../Common/Title";
import {DefaultListProps} from "../Common/DefaultListProps";
import {FeedDownloadButton} from "../Feed/List";
import {ManualExportButton} from "../Common/ExportButton";
import {order} from "../../resources";

export const ListPlain = ({...props }) => {
    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List sort={{ field: 'id', order: 'DESC' }} title={<EmptyTitle/>} {...DefaultListProps({operations})}  filters={false} bulkActionButtons={false}  {...props}>
                <Datagrid>
                    {<FieldGuesser label={'Name'} source={'name'}/>}
                    {<FieldGuesser source={'originId'}/>}
                    {<FieldGuesser label={'Status'} source={'statusString'}/>}
                    {readableFields.createdAt && <FieldGuesser source={'createdAt'}/>}
                    {<FeedDownloadButton fileName={'shipmentLabelName'} label={'Shipment Label'} source={'shipmentLabelUrl'}/>}
                    {<FeedDownloadButton fileName={'packagingLabelName'} label={'Pack Label'} source={'packagingLabelUrl'}/>}
                    <RecordAwareExportButton label={'Failed Orders'} />

                </Datagrid>
            </List>
        </Fragment>
    );
};

export const ListFinal = ListPlain;

export const RecordAwareExportButton = ({Component, ...props}) => {
    const {record, resource, ...rest} = props;
    return (
        <>
            {
                record.status === 2 && <ManualExportButton filter={{shipmentBatch: record.id, shipmentCreated: false, exportgroups: 'failed'}} resource={order.name} {...rest} />
            }
        </>
    );
}
