import {ListFinal} from "./List";
import {ShowFinal} from "./Show";
import {EditFinal,CreateFinal} from "./Edit";
import {warehouse as ResourceData} from "../../resources";

export default {
    name:   ResourceData.name,
    list:   ListFinal,
    edit:   EditFinal,
    create:   CreateFinal,
    show:   ShowFinal,
    options:   { label: ResourceData.label },
};
