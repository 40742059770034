import React  from "react";
import {Edit,SimpleForm, Create } from 'react-admin';
import InputGuesser from 'UI/Common/ApiPlateform/InputGuesser';
import {useResourceOptions} from "../../hooks";
import {CurrencyInputField} from "../Common/Currency";

const SimpleFormCommon = ({...props}) => {
    const { writableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.edit) return null;

    return (
        <SimpleForm {...props}>
            {writableFields.stock && <InputGuesser source='stock' label='stock'/>}
            {writableFields.price && <CurrencyInputField source='price' label='price'/>}
            {writableFields.deleted && <InputGuesser source='deleted' label='deleted'/>}
        </SimpleForm>
    )
};

export const EditFinal = ({...props}) => {
    return (
        <Edit undoable={false} {...props }>
            <SimpleFormCommon {...props}/>
        </Edit>
    );
};

export const CreateFinal = ({...props}) => {
    return (
        <Create undoable={false} {...props}>
            <SimpleFormCommon/>
        </Create>
    );
};
