import React  from "react";
import {Edit,SimpleForm, Create,TextInput } from 'react-admin';
import InputGuesser from 'UI/Common/ApiPlateform/InputGuesser';
import {useResourceOptions} from "../../hooks";
import { ArrayInput, SimpleFormIterator } from 'react-admin';

const SimpleFormCommon = ({...props}) => {
    const { writableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.edit) return null;

    return (
        <SimpleForm {...props}>
            {writableFields.code && <InputGuesser source='code' label='code'/>}
            {writableFields.type && <InputGuesser source='type' label='type'/>}
            {writableFields.storageType && <InputGuesser source='storageType' label='storageType'/>}
            {writableFields.position && <InputGuesser source='position' label='position'/>}
            <ArrayInput source="translations">
                <SimpleFormIterator>
                    {writableFields.name && <InputGuesser source='name' label='name'/>}
                    {writableFields.name && <InputGuesser source='locale' label='locale'/>}
                </SimpleFormIterator>
            </ArrayInput>

        </SimpleForm>
    )
};

export const EditFinal = ({...props}) => {
    return (
        <Edit undoable={false} {...props }>
            <SimpleFormCommon {...props}/>
        </Edit>
    );
};

export const CreateFinal = ({...props}) => {
    return (
        <Create undoable={false} {...props}>
            <SimpleFormCommon/>
        </Create>
    );
};
