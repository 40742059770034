import React  from "react";

import { Show,
    TabbedShowLayout,
    Tab
} from 'react-admin';
import { withStyles, createStyles } from '@material-ui/core/styles';
import {Invoice} from "./invoice";

const Title = ({ record }) => {
    return <span>{record ? `"${record.amazonOrderId}"` : ''}</span>;
};

const Styles = function (theme) {
    return createStyles({

    });
};

export const ShowPlain = (props) =>
{
    return (
        <Show title={<Title/>} {...props}>
            <TabbedShowLayout>
                <Tab label="invoice">
                    <Invoice/>
                </Tab>
                {/*<Tab label="summary" path="summary">*/}
                {/*    <TextField source="asin" />*/}
                {/*    <TextField source="sku" />*/}
                {/*</Tab>*/}

            </TabbedShowLayout>
        </Show>
    );
};

export const ShowFinal = withStyles(Styles)(ShowPlain);
