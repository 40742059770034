import React, { Fragment }   from "react";
import {
    Datagrid
} from 'react-admin';
import {List} from "../Common/List";
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import {useResourceOptions} from "../../hooks";
import {DefaultListProps} from "../Common/DefaultListProps";
import {CurrencyField} from "../Common/Currency";

export const ListPlain = ({...props }) => {
    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List filters={false} {...DefaultListProps({operations})} {...props}>
                <Datagrid>
                    {readableFields.runTime && <FieldGuesser showTime={true} source={'runTime'}/>}
                    {readableFields.budget && <CurrencyField source={'budget'}/>}
                    {/*{readableFields.scheduler && <FieldGuesser label={'Scheduler'} source={'scheduler.name'}/>}*/}
                </Datagrid>
            </List>
        </Fragment>
    );
};


export const ScheduleLogList = ({record,targetResource, resource,basePath,id,recordType, ...props}) => {
    return (
        <ListFinal bulkActionButtons={false} component={'div'} resource={targetResource} basePath={`/${targetResource}`} filter={{[recordType]: record.id}}
                   hasCreate={false} hasEdit={false} hasShow={false} hasList={true}
                   {...props}/>
    );
};

export const ListFinal = ListPlain;
